import Vue from 'vue'
import Vuex from 'vuex'
import api from "@/api.js"
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    "pending_filter_fast_request": false,
    // This parameter is set to true when App.vue:mounted() ended (when data are fetched)
    "app_mounted": false,
    // Current user Profile
    "profile": {
      "user_name": localStorage.getItem('user_name') || null,
      "hour": ""
    },
    "access_token": localStorage.getItem('access_token') || null,
    // "refresh_token": localStorage.getItem('refresh_token') || null,
    "searching": false,

    // Current active Company (todo : update each time a company is clicked -or hovered-)
    "activeBranchId": "",
    "selectedBranchId": "",
    "selectedBranchObject": {},
    "franceRegions": [],
    "franceStates": [],
    "franceCities": [],
    "companyActivities": [],
    "companyEnergies": [],
    "branches": [],
    "companies": [],
    "workforces": [],
    // "activeBranchObject": {},
    // Actual branches shown on the map
    "filteredBranches": [],
    // all branches indexed by siret
    "branches_by_siret": {},
    // the global filters
    "filters": {
      "pagination": { "limit": 10000000 },
      "filters": {
        "co2_emission": [],
        "n2o_emission": [],
        "ch4_emission": [],
        "siret": [],
        "region_id": [],
        "state_id": [],
        "city_id": [],
        "activity_id": [],
        "activity_nce_id": [],
        "activity_ind_id": [],
        "siren": [],
        "search_query": null,
        "distance_closest_city": [],
        "distance_depleted_tanks": [],
        "distance_terrestrial_aquifer": [],
        "distance_dunkerque": [],
        "energy": [],
        "audit_certif": [],
        "workforce": ["10", ">10000", "known"],
        "ppe": "unselected",
        "bbox": {
          "ne": [],
          "sw": []
        }
      }
    },
    // the list of visibles sirets: filtered
    "sirets": [],
    "sirets_count": -1,
    "filters_geo_count": 0,
    "filters_admin_count": 2,
    "filters_ges_count": 0,
    "filters_certif_count": 0,
    "filters_conso_count": 0,
    "filters_dist_count": 0,
    "filters_list_count": 0,
    "filters_all_count": 2,
    "zoom": 5,
    "center": [1, 46.870576],
    "zoom_nclicks": 0,
    // energies min and max values to fill the input default value
    "energies_min_max_cost": {},
    "energies_min_max_volume": {},
    // polluting Chart
    "chartdata": {},
    // others
    "show_overlay": false,
    "sidebar_toggled": 0,
    "sortBy": "corporate_name",
    "sortDesc": false,
    "distance_closest_city": [],
    "distance_depleted_tanks": [],
    "distance_terrestrial_aquifer": [],
    "distance_dunkerque": [],
    "filters_update_map_data": true,
  },
  getters: {
    is_authenticated: function (state) {
      return state.access_token != null
    },
    "profile": function (state) {
      return state.profile
    },
    "searching": function (state) {
      return state.searching
    },
    activeBranchObject(state) {
      if (!state.activeBranchId) {
        return false
      }
      return state.branches_by_siret[state.activeBranchId]
    },
    // selectedBranchObject(state) {
    //   if (!state.selectedBranchId) {
    //     return false
    //   }
    //   return state.branches_by_siret[state.selectedBranchId]
    // },
  },
  mutations: {
    update_local_storage: function (state, data) {
      // console.log("update_local_storage")
      localStorage.setItem('access_token', data.access)
      localStorage.setItem('refresh_token', data.refresh)
      state.access_token = data.access
      state.refresh_token = data.refresh
      // console.log("access token", state.access_token)
      // console.log("refresh token", state.refresh_token)
    },
    update_access(state, access_token) {
      state.access_token = access_token
    },
    destroy_token(state) {
      localStorage.removeItem('access_token')
      state.access_token = null
      state.refresh_token = null
    },
    searching(state, value) {
      state.searching = value
    },
    update_sirets(state, sirets) {
      // console.log("mutation update_sirets")
      state.sirets = sirets

    },
    update_sirets_count(state, count) {
      state.sirets_count = count
    },
    update_filters_geo_count(state, count) {
      state.filters_geo_count = count
    },
    update_filters_admin_count(state, count) {
      state.filters_admin_count = count
    },
    update_filters_certif_count(state, count) {
      state.filters_certif_count = count
    },
    update_filters_conso_count(state, count) {
      state.filters_conso_count = count
    },
    update_filters_ges_count(state, count) {
      state.filters_ges_count = count
    },
    update_filters_list_count(state, count) {
      state.filters_list_count = count
    },
    update_filters_dist_count(state, count) {
      state.filters_dist_count = count
    },
    update_filters_all_count(state, count) {
      state.filters_all_count = count
    },
    updateActiveBranch(state, siret) {
      state.activeBranchId = siret
    },
    // selected branch triggered open up
    updateSelectedBranch(state, siret) {
      // console.log("updateSelectedBranch", siret)
      state.selectedBranchId = siret
    },
    updateSelectedBranchObject(state, branch) {
      state.selectedBranchObject = branch
    },
    updateBranches(state, branches) {
      state.branches = branches
    },
    updateCompanies(state, companies) {
      state.companies = companies
    },
    update_branches_by_siret(state, data) {
      state.branches_by_siret = data
    },
    update_filters_bbox(state, bbox) {
      var actual_bbox = { "ne": [bbox.ne.lat, bbox.ne.lng], "sw": [bbox.sw.lat, bbox.sw.lng] }
      // console.log("mutation update_filters_bbox", bbox, actual_bbox)
      state.filters["filters"]["bbox"] = actual_bbox
    },
    update_filters(state, payload) {
      console.log("mutation update_filters", payload)
      state.filters["filters"][payload.key] = payload.value
    },
    reset_search_filter(state) {
      state.filters["filters"]["search_query"] = null
    },
  },
  actions: {
    "searching": async function (context, search_query) {
      context.commit("searching", true)
      // console.log("Searching : ", search_query)
      context.commit("update_filters", { "key": "search_query", "value": search_query })
      await context.dispatch("update_data")
      context.commit("searching", false)
    },
    login: async function (context, credentials) {
      // method from:
      // https://github.com/nishant-boro/django_rest-vuejs-auth/tree/master/backend
      let response = await fetch(api.get_url("token"),
        {
          "method": "post",
          "body": JSON.stringify(credentials),
          "headers": { "Content-Type": "application/json" },
        }
      )
      let payload = await response.json()
      context.state.profile.user_name = credentials.username
      context.state.profile.hour = credentials.hour
      localStorage.setItem('user_name', credentials.username)
      context.commit("update_local_storage", payload)
      router.push({ name: "MainApp" })
    },
    logout: async function (context) {
      // console.log("logout action")
      context.commit("destroy_token")
      router.push({
        name: 'Login',
        query: { timestamp: Date.now() }
      })
    },
    update_map_bounds: function (context, bbox) {
      // console.log("update_map_bounds", bbox)
      context.commit("update_filters_bbox", bbox)
      context.dispatch("update_data")
    },
    update_filters: function (context, payload) {
      // console.log("update_filters", payload)
      context.state.show_overlay = true
      context.commit("update_filters", payload)
      context.dispatch("update_data")
    },
    update_data: async function (context) {
      // console.log("init_data")
      // fetch branches data
      var response = await Vue.http
        .post(api.get_url("company2/filter-fast/"), context.state.filters)
        .catch(
          function (e) {
            if (e.body == "cancelled") {
              console.log("filter-fast request cancelled")
            }
            else {
              console.log("PB filtering", e)
            }
          }
        )
      if (response !== undefined) {
        context.commit("updateBranches", response.body)
        var branches_by_siret = {}
        var sirets = []
        response.body.forEach(
          function (branch) {
            branches_by_siret[branch.siret] = branch
            sirets.push(branch.siret)
          }
        )
        context.commit("update_branches_by_siret", branches_by_siret)
        // fetch list of sirets
        context.commit("update_sirets", sirets)
        var count = sirets.length
        context.commit("update_sirets_count", count)
        context.commit("reset_search_filter")
        var count_all = 0
        // count geo filters
        var count_geo = 0
        if (context.state.filters["filters"]["region_id"].length > 0) {
          count_geo = count_geo + 1
        }
        if (context.state.filters["filters"]["state_id"].length > 0) {
          count_geo = count_geo + 1
        }
        if (context.state.filters["filters"]["city_id"].length > 0) {
          count_geo = count_geo + 1
        }
        context.commit("update_filters_geo_count", count_geo)
        // count admin filters
        var count_admin = 0
        if (context.state.filters["filters"]["activity_id"].length > 0) {
          count_admin = count_admin + 1
        }
        if (context.state.filters["filters"]["activity_nce_id"].length > 0) {
          count_admin = count_admin + 1
        }
        if (context.state.filters["filters"]["activity_ind_id"].length > 0) {
          count_admin = count_admin + 1
        }
        if ((context.state.filters["filters"]["workforce"][0] !== "0")
          || (context.state.filters["filters"]["workforce"][1] !== ">10000")) {
          count_admin = count_admin + 1
        }
        context.commit("update_filters_admin_count", count_admin)
        // count audit filters
        var count_certif = 0
        if (context.state.filters["filters"]["audit_certif"].length > 0) {
          count_certif = count_certif + 1
        }
        if (context.state.filters["filters"]["ppe"] === "selected") {
          count_certif = count_certif + 1
        }
        context.commit("update_filters_certif_count", count_certif)
        // count ges filters
        var count_ges = 0
        if ((context.state.filters["filters"]["co2_emission"].length > 0) &&
          ((context.state.filters["filters"]["co2_emission"][0] !== "")
            || (context.state.filters["filters"]["co2_emission"][1] !== ""))) {
          count_ges = count_ges + 1
        }
        if ((context.state.filters["filters"]["n2o_emission"].length > 0) &&
          ((context.state.filters["filters"]["n2o_emission"][0] !== "")
            || (context.state.filters["filters"]["n2o_emission"][1] !== ""))) {
          count_ges = count_ges + 1
        }
        if ((context.state.filters["filters"]["ch4_emission"].length > 0) &&
          ((context.state.filters["filters"]["ch4_emission"][0] !== "")
            || (context.state.filters["filters"]["ch4_emission"][1] !== ""))) {
          count_ges = count_ges + 1
        }
        context.commit("update_filters_ges_count", count_ges)
        // count conso filters
        var count_conso = context.state.filters["filters"]["energy"].length
        context.commit("update_filters_conso_count", count_conso)
        // count dist filters
        var count_dist = 0
        if ((context.state.filters["filters"]["distance_closest_city"][0] > context.state.distance_closest_city[0])
          || (context.state.filters["filters"]["distance_closest_city"][1] < context.state.distance_closest_city[1])) {
          count_dist = count_dist + 1
        }
        if ((context.state.filters["filters"]["distance_depleted_tanks"][0] > context.state.distance_depleted_tanks[0])
          || (context.state.filters["filters"]["distance_depleted_tanks"][1] < context.state.distance_depleted_tanks[1])) {
          count_dist = count_dist + 1
        }
        if ((context.state.filters["filters"]["distance_terrestrial_aquifer"][0] > context.state.distance_terrestrial_aquifer[0])
          || (context.state.filters["filters"]["distance_terrestrial_aquifer"][1] < context.state.distance_terrestrial_aquifer[1])) {
          count_dist = count_dist + 1
        }
        if ((context.state.filters["filters"]["distance_dunkerque"][0] > context.state.distance_dunkerque[0])
          || (context.state.filters["filters"]["distance_dunkerque"][1] < context.state.distance_dunkerque[1])) {
          count_dist = count_dist + 1
        }
        context.commit("update_filters_dist_count", count_dist)
        // count list filters
        var count_list = 0
        if (context.state.filters["filters"]["siret"].length > 0) {
          count_list = count_list + 1
        }
        context.commit("update_filters_list_count", count_list)
        // count all filters
        count_all = count_admin + count_certif + count_conso + count_dist + count_geo + count_ges + count_list
        context.commit("update_filters_all_count", count_all)
      }
    },
    init_data: async function (context) {
      // console.log("init_data")
      // fetch branches data
      var response = await Vue.http
        .post(api.get_url("company2/filter-fast/"), context.state.filters)
        .catch((e) => console.log("PB filtering", e));
      context.commit("updateBranches", response.body)
      // var companies = []
      // var companies_tmp = []
      var branches_by_siret = {}
      var sirets = []
      response.body.forEach(
        function (branch) {
          branches_by_siret[branch.siret] = branch
          sirets.push(branch.siret)
        }
      )
      context.commit("update_branches_by_siret", branches_by_siret)
      // fetch list of sirets
      context.commit("update_sirets", sirets)
      var count = sirets.length
      context.commit("update_sirets_count", count)
      var response_siren = await Vue.http
        .post(api.get_url("company2/all-fast/"))
        .catch((e) => console.log("PB request all", e));
      context.commit("updateCompanies", response_siren.body)
      context.commit("reset_search_filter")
    },
    select_branch: async function (context, siret) {
      // console.log("select_branch", siret)
      if (siret) {
        var result = await Vue.http
          .post(api.get_url("company2/branch-profile/"), { "siret": siret })
          .catch((e) => console.log("PB fetching branches", e));
        // console.log("SelectedBranchObject", result.body)
        context.commit("updateSelectedBranchObject", result.body["branch"])
      }
      else {
        context.commit("updateSelectedBranchObject", false)
      }
      context.commit("updateSelectedBranch", siret)
      context.commit("updateActiveBranch", siret)

    },

    setProfileFilter: async function (context, payload) {
      context.commit("updateProfileFilter", payload)
    }
  },
  modules: {
  }
})
