import Vue from 'vue'

import { BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import '../assets/app.scss'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
