<template>
  <b-button
    @click="click()"
    pill
    target="_blank"
    variant="dark"
    size="sm"
    class="mr-1 ml-1 mt-3 mb-3"
  >
    <b-overlay spinner-variant="primary" opacity="0" :show="downloading">
      Exporter
    </b-overlay>
  </b-button>
</template>



<script>
// @click="click"

import Vue from "vue";
// import XLSX from "xlsx";
import api from "@/api.js";
import { saveAs } from 'file-saver';

export default {
  name: "DownloadButton",
  components: {},
  data() {
    return {
      fileName: "etablissements_cibles.xls",
      downloading: false,
      download_url: api.get_url('company2/download-json/'),
    };
  },
  methods: {
    async click() {
      this.startDownload();
      var response = await Vue.http
        .post(api.get_url("company2/download-json/"),  {
          sirets: this.$store.state.sirets,
        }, {responseType: 'blob'},)
        .catch((e) => console.log("PB downloading", e));
      saveAs(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), "établissements.xlsx")
      this.finishDownload();
    },
    startDownload() {
      this.downloading = true;
    },
    finishDownload() {
      this.downloading = false;
    },
  },
};
</script>