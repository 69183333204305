<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "App",
  components: {},
  mounted: async function () {
    console.log("app mounted");
  },
};
</script>

<style lang="scss">
@import "assets/app.scss";

/* here styles will be global to the App */
html,
body,
#app {
  height: 100%;
  min-height: 100%;
}
.full-height {
  height: 100%;
  // min-height: 100%;
}
.content-full-height {
  height: calc(100% - 4em);
  // overflow-y: scroll;
}

.mapindus-marker-icon {
  margin-left: -6px;
  margin-top: -6px;
  width: 12px;
  height: 12px;
  // transform: translate3d(400px, 510px, 0px);
  z-index: 510;
  opacity: 1;
  outline: currentcolor none medium;
  background: orange;
  border: 1px solid black;
  border-radius: 50%;
  // box-shadow: 1px 1px 3px 1px #555;
  cursor: pointer;
  // transform-origin: 0 0;
  display: block;
  // position: absolute;
  // left: 0;
  // top: 0;
}

.mapindus-active-marker-icon {
  margin-left: -6px;
  margin-top: -6px;
  width: 12px;
  height: 12px;
  // transform: translate3d(400px, 510px, 0px);
  z-index: 510;
  opacity: 1;
  outline: currentcolor none medium;
  background: red;
  border: 1px solid black;
  border-radius: 50%;
  // box-shadow: 1px 1px 3px 1px #555;
  cursor: pointer;
  // transform-origin: 0 0;
  display: block;
  // position: absolute;
  // left: 0;
  // top: 0;
}

.mapindus-cluster-icon {
  margin-left: -10px !important;
  margin-top: -10px !important;
  width: 20px !important;
  height: 20px !important;
  // transform: translate3d(400px, 510px, 0px);
  z-index: 510;
  opacity: 1;
  outline: currentcolor none medium;
  background: white;
  border: 1px solid black;
  border-radius: 50%;
  // box-shadow: 1px 1px 3px 1px #555;
  cursor: pointer;
  // transform-origin: 0 0;
  display: block;
  // position: absolute;
  // left: 0;
  // top: 0;
}

// .leaflet-markercluster-icon {

// }

.leaflet-fade-anim .leaflet-tile,
.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: auto !important;
}

.prunecluster {
  font-size: 12px;
  border-radius: 20px;
  transition: all 0.3s linear;
}
.leaflet-marker-icon.prunecluster-anim,
.leaflet-marker-shadow.prunecluster-anim,
.leaflet-markercluster-icon.prunecluster-anim {
  transition: all 0.3s linear;
}

.leaflet-zoom-anim .leaflet-zoom-animated.leaflet-marker-icon,
.leaflet-zoom-anim .leaflet-zoom-animated.leaflet-marker-shadow,
.leaflet-zoom-anim .leaflet-zoom-animated.leaflet-markercluster-icon {
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}
.prunecluster div {
  width: 30px;
  height: 30px;
  text-align: center;
  margin-left: 5px;
  margin-top: 5px;
  border-radius: 50%;
}
.prunecluster div span {
  line-height: 30px;
}

.prunecluster-small {
  background-color: #3783ae;
  /*background-color: rgba(181, 226, 140, 0.6);*/
}

.prunecluster-small div {
  width: 28px;
  height: 28px;
  background-color: #3783ae;
  color: #fff;
  /*background-color: rgba(110, 204, 57, 0.6);*/
}

.prunecluster-small div span {
  line-height: 28px;
}

.prunecluster-medium {
  background-color: #3783ae;
  /*background-color: rgba(241, 211, 87, 0.6);*/
}

.prunecluster-medium div {
  background-color: #3783ae;
  color: #fff;
  /*background-color: rgba(240, 194, 12, 0.6);*/
}

.prunecluster-large {
  background-color: #3783ae;
  /*background-color: rgba(253, 156, 115, 0.6);*/
}

.prunecluster-large div {
  width: 34px;
  height: 34px;
  background-color: #3783ae;
  color: #fff;
  /*background-color: rgba(241, 128, 23, 0.6);*/
}

.prunecluster-large div span {
  line-height: 34px;
}
</style>
