<template>
  <div>
    <b-form-group
      id="group-select-workforce"
      label="Effectif"
      label-for="select-workforce"
      content-cols="8"
      label-cols="4"
    >
      <vue-slider
        id="select-workforce"
        v-model="sliderWfValue"
        :data="workforceOptions"
        :marks="workforceOptions"
      />
      <br>
      <b-form-checkbox
        id="checkbox-eff"
        v-model="selected"
        unchecked-value="known"
        value="not_known"
      >
        Effectif non renseigné
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>


<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  components: {
    VueSlider,
  },
  name: "Filters",
  data() {
    return {
      sliderWfValue: ["10", ">10000"],
      workforceOptions: [
        "0",
        "1",
        "10",
        "20",
        "50",
        "100",
        "200",
        "500",
        "1000",
        "2000",
        "5000",
        ">10000",
      ],
      selected:"known"
    };
  },
  watch: {
    sliderWfValue: debounce(function (value) {
      console.log("Wf value changed", value);
      this.$store.dispatch("update_filters", {
        key: "workforce",
        value: [value[0], value[1], this.selected],
      });
    }, 500),
    selected: function (value) {
      this.$store.dispatch("update_filters", {
        key: "workforce",
        value: [this.sliderWfValue[0], this.sliderWfValue[1], value],
      });
    }
  },
  method: {
    tooltipFormater: function (value) {
      return this.rangeSliderWfTicks[value];
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title-card {
  color: #737373;
  font-weight: bold;
  text-align: left;
}
</style>

