let conf = {
    "api": {
        "root_url": "https://mapindus.bycrossdata.tech/api",
        "paths": {
            "login": "login"
        }
    },
    "tiles": {
        "root_url": "https://mapindus.bycrossdata.tech/tiles",
    }
}

export default conf
