<template>
  <b-navbar
    toggleable="lg"
    type="dark"
    variant="secondary"
    fixed="top"
    :sticky="true"
  >
    <b-navbar-brand
      ><b-img src="@/assets/logo.png" height="30px" /> MAPINDUS</b-navbar-brand
    >
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-col cols="6" id="select-branch">
        <multiselect
          v-if="app"
          v-model="dropdownCompanyValue"
          :options="companies"
          :multiple="true"
          :hide-selected="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          :internal-search="false"
          :options-limit="300"
          :show-no-results="false"
          :loading="isLoading"
          @search-change="debouncedSearch"
          placeholder="Recherchez une entreprise ..."
          label="search_name"
          track-by="search_name"
          :preselect-first="false"
          select-label=""
          selected-label="✓"
          deselect-label=""
          :option-height="20"
          :limit="3"
          :limitText="
            (count) => (count === 1 ? `${count} autre` : `${count} autres`)
          "
          :maxHeight="250"
        >
          <template slot="clear">
            <div
              class="multiselect__clear"
              v-if="dropdownCompanyValue.length"
              @mousedown.prevent.stop="clearAll()"
            ></div>
          </template>
          <template slot="caret"><span></span></template>
          <template>
            <span slot="noResult"
              >Aucune entreprise trouvée pour cette recherche...</span
            >
          </template>
        </multiselect>
      </b-col>
      <br />

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item :to="{ path: '/about' }" v-if="app" class="m-2"> À propos </b-nav-item>
        <b-nav-item :to="{ path: '/' }" v-else-if="logged" class="m-2"> Application </b-nav-item>
        <DownloadButton v-if="app" />
        <b-button
          pill
          id="support-button"
          variant="dark"
          size="sm"
          class="mr-1 ml-1 mt-3 mb-3"
          href="mailto:support-ademe@crossdata.tech"
          >Support</b-button
        >
        <b-popover
          target="support-button"
          triggers="hover"
          placement="bottom"
          text-variant="secondary"
        >
          support-ademe@crossdata.tech
        </b-popover>
        <b-button
          pill
          variant="outline-secondary"
          size="sm"
          class="mr-1 ml-1 mt-3 mb-3"
          v-if="logged"
          @click="$store.dispatch('logout')"
          ><b-icon
            icon="power"
            aria-hidden="true"
            variant="light"
            size="1.5"
          ></b-icon
        ></b-button>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { Multiselect } from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DownloadButton from "@/components/DownloadButton.vue";
import { ajaxFindCompany } from '@/components/CompaniesApi'
import _debounce from "lodash/debounce"

export default {
  components: { Multiselect, DownloadButton },
  name: "Navbar",
  props: {
    logged: Boolean,
    app: Boolean,
  },
  data: function () {
    return {
      search_text: null,
      dropdownCompanyValue: [],
      companies: [{'search_name':"Entrez un nom d'entreprise ou un SIREN", $isDisabled: true}],
      isLoading: false
    };
  },
  watch: {
    dropdownCompanyValue: function (val) {
      var sirensFiltered = [];
      val.forEach((v) => sirensFiltered.push(v.siren));
      console.log("filterred siren :", val)
      // console.log("Value region change", newFiltersId);
      this.$store.dispatch("update_filters", {
        key: "siren",
        value: sirensFiltered,
      });
    },
  },
  methods: {  
    debouncedSearch: _debounce(
      function(query){
        this.asyncFind(query)
      }, 700),
    clearAll: function () {
      this.dropdownCompanyValue = [];
    },
    search: async function () {
      // console.log("search for", this.search_text);
      this.$store.dispatch("searching", this.search_text);
      this.search_text = null;
    },
    asyncFind: function(query) {
      this.isLoading = true
      if (query !== "") {
        ajaxFindCompany(query, this.$store.state.companies).then(response => {
          this.companies = response
          this.isLoading = false
        })
      } else {
        this.companies = [{'search_name':"Entrez un nom d'entreprise ou un SIREN", $isDisabled: true}]
        this.isLoading = false
      }
    },
  }
}
</script>

<style scoped>
.navbar {
  height: 4em;
}

.input-nav {
  width: 450px;
}
</style>
