<template>
  <div class="full-height">
    <Navbar :logged="false" />

    <div class="container">
      <div class="row">
        <div class="col"></div>
        <div class="col">
          <br />
          <br />
          <br />
          <br />
          <b-img
            id="card-img"
            center
            src="@/assets/logo_circle.jpg"
            rounded="circle"
            alt="Circle image"
            width="100"
            height="100"
          ></b-img>

          <b-card center class="mb-2 mx-auto">
            <label for="id_username">Nom d’utilisateur&nbsp;:</label>
            <b-form-input
              type="text"
              id="id_username"
              v-model="credentials.username"
              placeholder="Nom d'utilisateur"
            ></b-form-input>
            <label for="id_password">Mot de passe&nbsp;:</label>

            <b-form-input
              size="0.1em"
              type="password"
              id="id_password"
              v-model="credentials.password"
              placeholder="Mot de passe"
            ></b-form-input>
            <br />
            <b-row class="text-center">
              <b-col>
                <b-button class="mx-auto" @click="login()" variant="secondary"
                  >Connexion</b-button
                >
              </b-col>
            </b-row>
          </b-card>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "Login",
  components: { Navbar },
  data: function () {
    return {
      credentials: { username: "", password: "", hour:Date.now()},
    };
  },
  methods: {
    login: function () {
      this.$store.dispatch("login", this.credentials);
    },
    logout: function () {
      // console.log("logout");
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style scoped>
#card-img {
  z-index: 1;
  position: inherit;
  margin-bottom: -1em;
}
.card {
  max-width: 20rem;
}
</style>
