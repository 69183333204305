<template>
  <div>
    <b-card no-body class="mb-1">
      <b-card-header id="polluting-header" header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-polluting variant="outline-light" class="title-card">▽ GES
          <b-badge>{{ count_ges }}</b-badge></b-button>
      </b-card-header>
      <b-tooltip target="polluting-header" triggers="hover" placement="left" variant="light">
        Ce filtre permet de d'afficher les établissements émettant des gaz à effet
        de serre.
      </b-tooltip>
      <b-collapse id="accordion-polluting" role="tabpanel">
        <b-card-body>
          <b-form-group id="group-input-co2" label="Emission de CO2 (tonnes/an)" label-for="input co2" content-cols="8"
            label-cols="4">
            <b-input-group id="group-input-co2">
              <b-col sm="2">
                <label>De :</label>
              </b-col>
              <b-col sm="4">
                <b-form-input type="number" debounce="500" v-model="min_value"></b-form-input>
              </b-col>
              <b-col sm="2">
                <label> à :</label>
              </b-col>
              <b-col sm="4">
                <b-form-input type="number" debounce="500" v-model="max_value"></b-form-input>
              </b-col>
            </b-input-group>
          </b-form-group>
          <br />
          <b-form-group
            id="group-input-n20"
            label="Emission de N2O (tonnes/an)"
            label-for="input n2o"
            content-cols="8"
            label-cols="4"
          >
          <b-input-group id="group-input-n2o">
            <b-col sm="2">
              <label>De :</label>
            </b-col>
            <b-col sm="4">
              <b-form-input
                type="number"
                debounce="500"
                v-model="min_value_n2o"
              ></b-form-input>
            </b-col>
            <b-col sm="2">
              <label> à :</label>
            </b-col>
            <b-col sm="4">
              <b-form-input
                type="number"
                debounce="500"
                v-model="max_value_n2o"
              ></b-form-input>
            </b-col>
          </b-input-group>
          </b-form-group>
          <br />
          <b-form-group
            id="group-input-ch4"
            label="Emission de CH4 (tonnes/an)"
            label-for="input ch4"
            content-cols="8"
            label-cols="4"
          >
          <b-input-group id="group-input-ch4">
            <b-col sm="2">
              <label>De :</label>
            </b-col>
            <b-col sm="4">
              <b-form-input
                type="number"
                debounce="500"
                v-model="min_value_ch4"
              ></b-form-input>
            </b-col>
            <b-col sm="2">
              <label> à :</label>
            </b-col>
            <b-col sm="4">
              <b-form-input
                type="number"
                debounce="500"
                v-model="max_value_ch4"
              ></b-form-input>
            </b-col>
          </b-input-group>
          </b-form-group>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>


<script>

export default {
  name: "Filters",
  data() {
    return {
      max_value: "",
      min_value: "",
      max_value_n2o: "",
      min_value_n2o: "",
      max_value_ch4: "",
      min_value_ch4: "",
    };
  },
  watch: {
    min_value: function (value) {
      console.log("Co2 value changed", value);
      var payload = {
        key: "co2_emission",
        value: [value, this.max_value],
      };
      this.$store.dispatch("update_filters", payload);
    },
    max_value: function (value) {
      console.log("Co2 value changed", value);
      var payload = {
        key: "co2_emission",
        value: [this.min_value, value],
      };
      this.$store.dispatch("update_filters", payload);
    },
    min_value_n2o: function (value) {
      console.log("N2O value changed", value);
      var payload = {
        key: "n2o_emission",
        value: [value, this.max_value_n2o],
      };
      this.$store.dispatch("update_filters", payload);
    },
    max_value_n2o: function (value) {
      console.log("N2O value changed", value);
      var payload = {
        key: "n2o_emission",
        value: [this.min_value_n2o, value],
      };
      this.$store.dispatch("update_filters", payload);
    },
    min_value_ch4: function (value) {
      console.log("CH4 value changed", value);
      var payload = {
        key: "ch4_emission",
        value: [value, this.max_value_ch4],
      };
      this.$store.dispatch("update_filters", payload);
    },
    max_value_ch4: function (value) {
      console.log("CH4 value changed", value);
      var payload = {
        key: "ch4_emission",
        value: [this.min_value_ch4, value],
      };
      this.$store.dispatch("update_filters", payload);
    },
  },

  computed: {
    count_ges() {
      return this.$store.state.filters_ges_count;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title-card {
  color: #737373;
  font-weight: bold;
  text-align: left;
}
</style>

