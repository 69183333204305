<template>
  <MapboxglMap class="col-6"></MapboxglMap>
</template>

<script>
import mixins from "@/mixins.js";
import MapboxglMap from "@/components/MapboxglMap.vue";

export default {
  name: "RightPanel",
  components: {
    MapboxglMap
  },
  mixins: [mixins.LoadingMixin],
  props: {
    msg: String,
  },
  data: function () {
    return {};
  },
};
</script>

<style scoped>
</style>
