<template>
  <div
    v-if="!hideBranchDetail"
    id="branch-detail"
    class="full-content-height"
    style="overflow: clip auto"
  >
    <div class="title">
      <h5 class="m-auto">Détails sur l'établissement selectionné</h5>
      <b-button
        variant="outline-light"
        size="sm"
        class="mr-1 ml-1 mt-3 mb-3"
        v-b-toggle.sidebar-left
        @click="(payload) => this.selectBranch(false)"
        ><b-icon icon="X" aria-hidden="true" variant="primary" size="2"></b-icon
      ></b-button>
    </div>
    <br />
    <b-row class="justify-content-md-center">
      <b-col>
        <b-row>
          <b-col class="title">
            <h5>{{ selectedBranchObject["corporate_name"] }}</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p>SIRET : {{ selectedBranchObject["siret"] }}</p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="3">
        <b-button v-b-modal.modal-comment variant="light" size="sm" class="mr-1 ml-1 mt-3 mb-3" style="height:50px"
          @click="() => this.fetchComments()"
          ><b-icon
            icon="filter-left"
            aria-hidden="true"
            variant="text"
            size="2"
          ></b-icon
          >COMMENTAIRES</b-button
        >
        <b-modal id="modal-comment" centered size="lg" :title="selectedBranchObject['corporate_name']" 
          cancel-title="Annuler" ok-title="Ajouter" scrollable header-text-variant="primary"
          @ok="() => this.addComment()">
          <h5>Historique des commentaires</h5>
            <b-table v-if="comments.length > 0" small outlined :fields="fields_comments" :items="comments">
              <template #cell(comment)="data">
                <span v-html="data.value"></span>
              </template>
            </b-table>
            <p v-else>Aucun commentaire pour cet établissement</p>
          <h5>Ajouter un commentaire</h5>
          <b-form-textarea
            id="comment-textarea"
            placeholder="Saisissez un commentaire"
            rows="3"
            max-rows="8"
            v-model="commentText"
          ></b-form-textarea>
        </b-modal>
      </b-col>
      <b-col cols="4">
        <b-button
          variant="light"
          size="sm"
          class="mr-1 ml-1 mt-3 mb-3"
          @click="() => this.zoomToSelectedBranch()"
          ><b-icon
            icon="geo-alt-fill"
            aria-hidden="true"
            variant="text"
            size="2"
          ></b-icon
          >{{ selectedBranchObject["address"] }}</b-button
        >
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col sm="12">
        <b-row>
          <b-col>
            <h6 class="title">Actif depuis le</h6>
            <p>{{ selectedBranchObject["creation_date"] }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h6 class="title">Activité</h6>
            <p>{{ selectedBranchObject["activity__name"] }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h6 class="title">Effectif</h6>
            <p>{{ selectedBranchObject["workforce__label"] }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="selectedBranchObject['audit_status']">
            <h6 class="title">
              Audit énergétique et/ou certification iso-50001
            </h6>
            <p>{{ selectedBranchObject["audit_status"] }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="energy.length > 0">
            <h6 class="title">
              Consommations d'énergies de l'entreprise
            </h6>
            <div>
              <b-table small outlined :fields="fields" :items="energy"></b-table>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h6 class="title">Liens utiles</h6>
            <b-button
              :href="selectedBranchObject['url']"
              pill
              variant="primary"
              size="sm"
              :disabled="selectedBranchObject['url'] === ''"
              class="mr-1 ml-1 mt-1 mb-1"
              target="_blank"
              >Plateforme d'audit</b-button
            >
            <b-button
              :disabled="ppe === ''"
              :href="ppe"
              pill
              variant="primary"
              size="sm"
              class="mr-1 ml-1 mt-1 mb-1"
              target="_blank"
              >PPE</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <line-chart />
      </b-col>
    </b-row>
  </div>
</template>


<script>
import LineChart from "./PollutingGraph.vue";
import api from "@/api.js";
import Vue from "vue";

export default {
  components: {
    LineChart,
  },
  name: "BranchProfile",
  data() {
    return {
      energy:[],
      fields: [
        { key: 'energy__label', label: 'Energie'},
        { key: 'volume', label: 'Volume', formatter: value => {
              return value.toLocaleString()
            }
        },
        { key: 'energy__volume_unit', label: 'Unité'},
        { key: 'cost', label: 'Coût', formatter: value => {
              return value.toLocaleString()
            }
        },
        { key: 'energy__cost_unit', label: 'Unité'},
      ],
      comments:[],
      fields_comments: [
        { key: 'comment', label: 'Commentaire'},
        { key: 'date', label: 'Date'},
        { key: 'author', label: 'Auteur'},
      ],
      commentText: '',
      ppe: ''
    }
  },
  mounted() {},
  methods: {
    selectBranch: function (payload) {
      this.$store.dispatch("select_branch", payload);
    },
    zoomToSelectedBranch: function () {
      this.$store.state.zoom = 8;
      this.$store.state.zoom_nclicks++;
    },
    fetchEnergyData: async function (sir) {
      var options = { params: { siret: sir } };
      var response = await Vue.http
        .get(api.get_url("company/energy/data", options))
        .catch((e) => console.error("PB fetching energies", e));
      this.energy = response.body
    },
    fetchPPEData: async function (sir) {
      var options = { params: { siret: sir } };
      var response = await Vue.http
        .get(api.get_url("company/ppe/data", options))
        .catch((e) => console.error("PB fetching ppe", e));
      this.ppe = response.body
    },
    fetchComments: async function () {
      var options = { params: { siret: this.$store.state.selectedBranchId } };
      var response = await Vue.http
        .get(api.get_url("company/comments/data", options))
        .catch((e) => console.error("PB fetching comments", e));
      this.comments = response.body
    },
    addComment: async function () {
      // Request to an api that save the result in db
      var options = { params: { 
        siret: this.$store.state.selectedBranchId, 
        username: this.$store.state.profile.user_name, 
        comment: this.commentText.split("\n").join("<br />") } 
      };
      await Vue.http
        .get(api.get_url("company/comments/save", options))
        .catch((e) => console.error("PB fetching comments", e));
      this.commentText = ''
    }
  },
  computed: {
    selectedBranchObject() {
      var branch = this.$store.state.selectedBranchObject;
      this.fetchEnergyData(this.$store.state.selectedBranchId)
      this.fetchPPEData(this.$store.state.selectedBranchId)
      return branch;
    },
    hideBranchDetail() {
      // console.log("hideBranchDetail", this.$store.state.selectedBranchId);
      if (this.$store.state.selectedBranchId) {
        return false;
      } else {
        return true;
      }
    },
    energies() {
      return this.fetchEnergyData(this.$store.state.selectedBranchId)
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#branch-detail {
  max-height: calc(100vh - 10em);
}
table {
  cursor: pointer;
  width: 95%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.bi-list-ul {
  cursor: pointer;
}
.bi-arrow-repeat {
  cursor: pointer;
}

.title {
  display: flex;
  text-align: left;
  color: #156c9e;
}
.title-card {
  color: #808080;
  font-weight: bold;
  text-align: left;
}
</style>
