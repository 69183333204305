<template>
  <div class="full-height">
    <Navbar :logged="true" :app="true"/>

    <div class="main-app content-full-height">
      <div class="container-fluid full-height">
        <div class="row full-height">
          <LeftPanel class="above-right full-height" />
          <RightPanel class="no-padding full-height" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import LeftPanel from "@/components/LeftPanel.vue";
import RightPanel from "@/components/RightPanel.vue";
import api from "@/api.js";
import Vue from "vue";

export default {
  name: "MainApp",
  components: {
    Navbar,
    LeftPanel,
    RightPanel,
  },
  mounted: async function () {
    // update window height
    await this.fetchRegions();
    console.log("regions fetched")
    await this.fetchStates();
    console.log("states fetched")
    await this.fetchCities();
    console.log("cities fetched")
    await this.fetchActivities();
    await this.fetchActivitiesNCE();
    await this.fetchActivitiesInd();
    await this.fetchDistancesMax();
    console.log("distances max fetched")
    await this.fetchEnergies();
    console.log("energies fetched")
    await this.fetchEnergiesMinMax();
    console.log("energies min max fetched")
    await this.trackLogin();
    await this.$store.dispatch("init_data");
    this.$store.state.app_mounted = true;
  },
  methods: {
    trackLogin: async function () {
      var options = { params: { user: this.$store.state.profile.user_name } };
      await Vue.http
        .get(api.get_url("account/login/", options))
        .catch((e) => console.error("PB tracking user", e));
    },
    fetchRegions: async function () {
      var response = await Vue.http
        .get(api.get_url("geo/region/list/"))
        .catch((e) => console.error("PB fetching regions", e));
      this.$store.state.franceRegions = response.body;
    },
    fetchStates: async function () {
      var response = await Vue.http
        .get(api.get_url("geo/state/list/"))
        .catch((e) => console.error("PB fetching states", e));
      this.$store.state.franceStates = response.body;
    },
    fetchCities: async function () {
      var response = await Vue.http
        .get(api.get_url("geo/city/list/"))
        .catch((e) => console.error("PB fetching cities", e));
      // console.log(response)
      this.$store.state.franceCities = response.body;
      // console.log(this.$store.state.franceCities);
    },
    fetchActivitiesNCE: async function () {
      var response = await Vue.http
        .get(api.get_url("company/activityNCE/list/"))
        .catch((e) => console.error("PB fetching NCE activities", e));
      // console.log(response)
      this.$store.state.companyActivitiesNCE = response.body;
    },
    fetchActivitiesInd: async function () {
      var response = await Vue.http
        .get(api.get_url("company/activityInd/list/"))
        .catch((e) => console.error("PB fetching Ind activities", e));
      // console.log(response)
      this.$store.state.companyActivitiesInd = response.body;
    },
    fetchActivities: async function () {
      var response = await Vue.http
        .get(api.get_url("company/activity/list/"))
        .catch((e) => console.error("PB fetching activities", e));
      // console.log(response)
      this.$store.state.companyActivities = response.body;
      let preselected_activity_ids = [];
      this.$store.state.companyActivities
        .filter((activity) => activity.preselected)
        .forEach((activity) =>
          activity.preselected
            ? preselected_activity_ids.push(activity.id)
            : null
        );
      this.$store.state.filters.filters.activity_id = preselected_activity_ids;
      // console.log(this.$store.state.companyActivities);
    },
    fetchEnergies: async function () {
      var response = await Vue.http
        .get(api.get_url("company/energy/list/"))
        .catch((e) => console.error("PB fetching energies", e));
      // console.log("Energies", response.body);
      this.$store.state.companyEnergies = response.body;
    },
    fetchEnergiesMinMax: async function () {
      var response = await Vue.http
        .get(api.get_url("company/energy_min_max/dict/"))
        .catch((e) => console.error("PB fetching energies min and max", e));
      // console.log("Energies", response.body);
      this.$store.state.energies_min_max_cost = response.body["cost"];
      this.$store.state.energies_min_max_volume = response.body["volume"];
    },
    fetchDistancesMax: async function () {
      var response = await Vue.http
        .get(api.get_url("geo/distances_max/dict/"))
        .catch((e) => console.error("PB fetching distances max", e));
      this.$store.state.distance_closest_city = [0, Math.ceil(response.body["distance_closest_city__max"])];
      this.$store.state.distance_depleted_tanks = [0, Math.ceil(response.body["distance_depleted_tanks__max"])];
      this.$store.state.distance_terrestrial_aquifer = [0, Math.ceil(response.body["distance_terrestrial_aquifer__max"])];
      this.$store.state.distance_dunkerque = [0, Math.ceil(response.body["distance_dunkerque__max"])];
    },
  }
};
</script>

<style scoped>
.above-right {
  box-shadow: 3px 3px 15px gray;
  z-index: 1010;
}
.no-padding {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
