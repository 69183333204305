<template>
  <div v-show="hideBranchDetail" class="px-3 py-4">
    <b-overlay :show="show" rounded="sm" spinner-variant="primary">
      <div id="branch-list">
        <div class="title" v-if="count == 1">
          <h3>{{ count }} établissement</h3>
        </div>
        <div class="title" v-if="count > 1">
          <h3>{{ count.toLocaleString() }} établissements</h3>
        </div>
        <div class="alert" v-if="count == 0">
          <b-alert variant="secondary" show>Aucun établissement ne correspond à votre recherche. <br>Veuillez supprimer
            quelques filtres.</b-alert>
        </div>
        <b-row class="justify-content-md-center" align-v="center" v-if="count == -1">
          <b-col>
            <b-overlay :show="true" rounded="sm" spinner-variant="primary">
              <b-card style="height: 500px;"></b-card>
            </b-overlay>
          </b-col>
        </b-row>

        <b-table v-show="$store.state.sirets_count > 0" id="branches-table" ref="branches_table" hover responsive
          no-sort-reset :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" sticky-header="true" primary-key="siret"
          :items=branches_provider :current-page="currentPage" :per-page="perPage" :fields="fields"
          @row-clicked="(item) => this.select_branch(item.siret)"
          @row-hovered="(item) => this.updateActiveBranch(item.siret)"
          @row-unhovered="(item) => this.updateActiveBranch(false)">
        </b-table>

        <b-pagination v-show="$store.state.sirets_count > perPage" v-model="currentPage"
          :total-rows="$store.state.sirets_count" :per-page="perPage" aria-controls="branches-table" align="center"
          hide-goto-end-buttons hide-ellipsis pills></b-pagination>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  components: {},
  name: "BranchesList",
  props: {
    msg: String,
  },
  data() {
    // let selectedItemIndex = this.$store.state.filteredBranches;
    return {
      sortBy: this.$store.state.sortBy,
      sortDesc: this.$store.state.sortDesc,
      header: [{ key: "siret", label: "SIRET", width: 100 }],
      fields: [
        // {
        //   key: "siret",
        //   label: "Siret",
        //   sortable: true
        // },
        {
          key: "corporate_name",
          label: "Nom de l'établissement",
          sortable: true
        },
        {
          key: "city__name",
          label: "Ville",
        },
        {
          key: "quantity",
          label: "Emissions de CO2 (kg/an)",
          sortable: true,
          formatter: value => {
            return value.toLocaleString()
          }
        },
      ],
      perPage: 25,
      visible_branches: [],
      currentPage: 1,
      show: false
    };
  },
  watch: {
    // "$store.state.sirets": function () {
    //   console.log("currentPage", this.currentPage);
    //   this.currentPage = 1;
    // },
    "$store.state.sirets_count": function () {
      console.log("currentPage", this.currentPage);
      this.currentPage = 1;
      try {
        this.$refs.branches_table.refresh()
      } catch (error) {
        console.log("no ref branches_table")
      }
    },
    "$store.state.show_overlay": function (value) {
      this.show = value;
    },
    sortBy: function (value) {
      this.$store.state.sortBy = value
    },
    sortDesc: function (value) {
      this.$store.state.sortDesc = value
    }
  },
  computed: {
    // branches() {
    //   var branches = [];
    //   for (var siret of this.$store.state.sirets) {
    //     var branch = this.$store.state.branches_by_siret[siret];
    //     branches.push(branch);
    //   }
    //   return branches;
    // },
    hideBranchDetail() {
      if (this.$store.state.selectedBranchId) {
        return false;
      } else {
        return true;
      }
    },
    count() {
      return this.$store.state.sirets_count;
    }
  },
  methods: {
    ...mapMutations([
      "updateActiveBranch",
      "updateProfileFilterState",
      "updateSelectedBranch",
    ]),
    select_branch: function (payload) {
      // console.log(payload);
      this.$store.dispatch("select_branch", payload);
    },
    set_active_branch: function (branch) {
      // console.log("hovered branch", branch);
      this.updateActiveBranch(branch.siret);
    },
    branches_provider: async function (ctx) {
      console.log("branches_provider", ctx)
      var self = this
      var offset = self.perPage * (self.currentPage - 1)
      var limit = offset + self.perPage

      var page_sirets = this.$store.state.sirets.slice(offset, limit)
      console.log(limit, offset, page_sirets)
      var branches = [];
      for (var siret of page_sirets) {
        var branch = this.$store.state.branches_by_siret[siret];
        branches.push(branch);
      }
      return branches;
    }
  },
};
</script>

<style scoped>
.table-responsive {
  cursor: pointer;
  max-height: calc(100vh - 18em);
}

thead {
  cursor: default;
}

.bi-list-ul {
  cursor: pointer;
}

.bi-arrow-repeat {
  cursor: pointer;
}

.title {
  display: flex;
  text-align: left;
  color: #187db6;
}

.title-card {
  color: #808080;
  font-weight: bold;
  text-align: left;
}

.alert {
  display: flex;
  margin: auto;
  height: 250px;
  align-items: center;
  text-align: center;
}
</style>
