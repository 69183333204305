import '@babel/polyfill'
import 'leaflet/dist/leaflet.css';
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import VueResource from 'vue-resource'

Vue.use(VueResource);
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)

Vue.http.interceptors.push((request, next) => {
  if (store.getters.is_authenticated === true) {
    let token = store.state.access_token
    request.headers.set("Authorization", `Bearer ${token}`)
    request.headers.set('Accept', 'application/json')

    if (request.url.includes("branch-profile")){

      if (store.state.pending_branch_profile_request){
        // console.log("pending", store.state.pending_branch_profile_request)
        store.state.pending_branch_profile_request.abort()
      }
      store.state.pending_branch_profile_request = request
    }

    if (request.url.includes("filter-fast/?paginated")){
      if (store.state.pending_filter_fast_request){
        // already a request: let it finish and cancel the new one...
        // console.log("pending", store.state.pending_filter_fast_request)
        // store.state.pending_filter_fast_request.abort()
        request.abort()
      } else {
        store.state.pending_filter_fast_request = request
      }
      // if (store.state.pending_filter_fast_request){
      //   // already a request: let it finish and cancel the new one...
      //   // console.log("pending", store.state.pending_filter_fast_request)
      //   store.state.pending_filter_fast_request.abort()
      // }
      // store.state.pending_filter_fast_request = request
    }

    if (request.url.includes("filter-count")){

      if (store.state.pending_filter_count_request){
        // console.log("pending", store.state.pending_filter_count_request)
        store.state.pending_filter_count_request.abort()
      }
      store.state.pending_filter_count_request = request
    }

  next(async function (response) {
    // TODO: add refresh mechanism with Vue-resource seems hard
    // switch to axios: https://blog.digital-craftsman.de/axios-interceptor-to-refresh-jwt-token-after-expiration/
    // for now just logout if bad token and settings.ACCESS_TOKEN_LIFETIME = 2 days
    if ((response.ok === false) && (response.status === 401)) {
      await store.dispatch("logout")
    }
    else if ((response.ok === false) && (request.url.includes('filter-fast')) && (response.status === 0)){
      // console.log("Cancelled request")
      response.body = "cancelled"
    }
    else if (request.url.includes('filter-fast')){
      store.state.pending_filter_fast_request = false
    }
    return response
  });
}
})


Vue.config.productionTip = false


new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

// eslint-disable-next-line
// delete L.Icon.Default.prototype._getIconUrl
// // eslint-disable-next-line
// L.Icon.Default.mergeOptions({
//   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png')
// })
