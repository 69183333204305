<template>
  <b-card no-body class="mb-1">
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button block v-b-toggle.accordion-list variant="outline-light" class="title-card">▽ LISTE D'ÉTABLISSEMENTS
        <b-badge>{{ count_list }}</b-badge></b-button>
    </b-card-header>
    <b-collapse id="accordion-list" role="tabpanel">
      <b-card-body>
        <b-card-text><b>Ce filtre permet d'afficher dans l'application une liste pré-définie d'établissements</b>.
          Pour ce faire, sélectionnez un fichier au format .xlsx ou .csv qui contient des SIRET dans la
          première colonne. Voici un exemple de format :
        </b-card-text>
        <b-alert v-if="formatIsWrong" show variant="danger">L'extension '{{ wrongFormat }}' n'est pas reconnue, le fichier
          attendu doit avoir l'extension .xlsx ou .csv</b-alert>
        <b-table small outlined :items="[
          { SIRET: 40256974200014 },
          { SIRET: 21547896500001 },
          { SIRET: 89621475200024 },
          { SIRET: 38528947400003 },
        ]"></b-table>
        <b-row class="justify-content-md-center mt-3">
          <b-col cols="8">
            <b-form-file v-model="fileBranch" class="mt-3" plain
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"></b-form-file>
          </b-col>
          <b-col cols="1" class="my-auto">
            <b-button variant="outline-primary" @click="removeFileFilter()">
              <b-icon icon="trash-fill"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import readXlsxFile from "read-excel-file";
import Papa from "papaparse";
//var csv is the CSV file with headers
export default {
  name: "FileImport",

  data() {
    return {
      wrongFormat: false,
      formatIsWrong: false,
      fileBranch: null,
    };
  },
  watch: {
    wrongFormat: function (val) {
      console.log("New val ", val);
      this.formatIsWrong = Boolean(val);
    },
    fileBranch: async function (file) {
      var self = this;
      var sirets = null;
      if (file != null) {
        var splitted = file.name.split(".");
        var extension = splitted[splitted.length - 1];
        if (extension === "xlsx") {
          readXlsxFile(file).then((data) => {
            // `data` is an array of rows
            // each row being an array of cells.
            sirets = self.extractSirets(data);
            console.log("data xlsx", data);
            console.log("sirets xlsx", sirets);
            var payload = {
              key: "siret",
              value: sirets,
            };
            self.$store.dispatch("update_filters", payload);
          });
          self.wrongFormat = false;
        } else if (extension == "csv") {
          Papa.parse(file, {
            complete: function (results) {
              var data = results.data;

              sirets = self.extractSirets(data);
              console.log("data csv", data);

              console.log("sirets csv", sirets);
              var payload = {
                key: "siret",
                value: sirets,
              };
              self.$store.dispatch("update_filters", payload);
            },
          });
          self.wrongFormat = false;
        } else {
          self.wrongFormat = `.${extension}`;
          console.error("Unknown file extension : ", extension);
        }
      }
    },
  },

  computed: {
    count_list() {
      return this.$store.state.filters_list_count;
    },
  },

  methods: {
    removeFileFilter: function () {
      this.wrongFormat = false;
      this.fileBranch = null;
      var payload = {
        key: "siret",
        value: [],
      };
      this.$store.dispatch("update_filters", payload);
    },
    extractSirets: function (data) {
      const regex = new RegExp("[0-9]{14}");

      var sirets = [];
      data.forEach(function (row) {
        row.forEach(function (item) {
          if (regex.test(item) && item.length == 14) {
            sirets.push(item);
          }
        });
      });
      return sirets;
    },
    csvJSON: function (csv) {
      var lines = csv.split("\n");

      var result = [];

      // NOTE: If your columns contain commas in their values, you'll need
      // to deal with those before doing the next step
      // (you might convert them to &&& or something, then covert them back later)
      // jsfiddle showing the issue https://jsfiddle.net/
      var headers = lines[0].split(",");

      for (var i = 1; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(",");

        for (var j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }

        result.push(obj);
      }

      //return result; //JavaScript object
      return JSON.stringify(result); //JSON
    },
  },
};
</script>
<style scoped>
.title {
  display: flex;
  text-align: left;
  color: #156c9e;
}

.title-card {
  color: #808080;
  font-weight: bold;
  text-align: left;
}

.card-text {
  color: #000000;
  text-align: left;
}

.table {
  margin: auto;
  width: 50%
}
</style>
