<template>
  <div>
    <!-- Distances filter part -->
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-dist variant="outline-light" class="title-card">▽ DISTANCES
          <b-badge>{{ count_dist }}</b-badge></b-button>
      </b-card-header>
      <b-collapse id="accordion-dist" role="tabpanel">
        <!-- First Slider -->
        <b-card-body>
          <b-form-group id="group-select-dist1" label="Distance à une grande ville"
            label-for="select-closest-city-distance" content-cols="8" label-cols="4">
            <vue-slider id="select-closest-city-distance" v-model="sliderClosestCityValue" :min="0" :max="maxcity"
              :marks="rangeSliderCityTicks" />
          </b-form-group>
          <b-tooltip target="group-select-dist1" triggers="hover" placement="top" variant="light">
            Ce filtre permet de filtrer les établissements se trouvant à plus/moins
            de x km d'une grande ville. Les villes prises en compte sont les
            100 plus peuplées de France.
          </b-tooltip>
        </b-card-body>
        <!-- Second Slider -->
        <b-card-body>
          <b-form-group id="group-select-dist2" label="Distance aux zones de stockage de CO2 : réservoirs déplétés (km)"
            label-for="select-closest-depleted-tanks-distance" content-cols="8" label-cols="4">
            <vue-slider id="select-closest-depleted-tanks-distance" v-model="sliderClosestDepletedTanksValue" :min="0"
              :max="maxtank" :marks="rangeSliderDepletedTanksTicks" />
          </b-form-group>
        </b-card-body>
        <!-- Third Slider -->
        <b-card-body>
          <b-form-group id="group-select-dist3"
            label="Distance aux zones de stockage de CO2 : zones acquifères terrestres (km)"
            label-for="select-closest-distance-terrestrial-aquifer" content-cols="8" label-cols="4">
            <vue-slider id="select-closest-distance-terrestrial-aquifer" v-model="sliderClosestTerrestrialAquiferValue"
              :min="0" :max="maxaqui" :marks="rangeSliderTerrestrialAquiferTicks" />
          </b-form-group>
        </b-card-body>
        <!-- Fourth Slider -->
        <b-card-body>
          <b-form-group id="group-select-dist4"
            label="Distance aux zones de stockage de CO2 : zones acquifères marines (distance au port de Dunkerque en km)"
            label-for="select-closest-distance-dunkerque" content-cols="8" label-cols="4">
            <vue-slider id="select-closest-distance-dunkerque" v-model="sliderClosestDunkerqueValue" :min="0"
              :max="maxdunk" :marks="rangeSliderDunkerqueTicks" />
          </b-form-group>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>


<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  components: {
    VueSlider,
  },
  name: "Filters",
  data() {
    var rangeSliderCityTicks = {};
    for (let i = 0; i < this.$store.state.distance_closest_city[1]; i += 30) {
      rangeSliderCityTicks[i] = i + "km";
    }
    var rangeSliderDepletedTanksTicks = {};
    for (let i = 0; i < this.$store.state.distance_depleted_tanks[1]; i += 40) {
      rangeSliderDepletedTanksTicks[i] = i + "km";
    }
    var rangeSliderTerrestrialAquiferTicks = {};
    for (let i = 0; i < this.$store.state.distance_terrestrial_aquifer[1]; i += 100) {
      rangeSliderTerrestrialAquiferTicks[i] = i + "km";
    }
    var rangeSliderDunkerqueTicks = {};
    for (let i = 0; i < this.$store.state.distance_dunkerque[1]; i += 200) {
      rangeSliderDunkerqueTicks[i] = i + "km";
    }
    return {
      maxcity: this.$store.state.distance_closest_city[1],
      sliderClosestCityValue:
        this.$store.state.distance_closest_city,
      maxtank: this.$store.state.distance_depleted_tanks[1],
      sliderClosestDepletedTanksValue:
        this.$store.state.distance_depleted_tanks,
      maxaqui: this.$store.state.distance_terrestrial_aquifer[1],
      sliderClosestTerrestrialAquiferValue:
        this.$store.state.distance_terrestrial_aquifer,
      maxdunk: this.$store.state.distance_dunkerque[1],
      sliderClosestDunkerqueValue:
        this.$store.state.distance_dunkerque,
      rangeSliderCityTicks: rangeSliderCityTicks,
      rangeSliderDepletedTanksTicks: rangeSliderDepletedTanksTicks,
      rangeSliderTerrestrialAquiferTicks: rangeSliderTerrestrialAquiferTicks,
      rangeSliderDunkerqueTicks: rangeSliderDunkerqueTicks,
    };
  },
  watch: {
    sliderClosestCityValue: debounce(function (val) {
      // console.log("Value ClosetCity change", val);
      var payload = {
        key: "distance_closest_city",
        value: val,
      };
      this.$store.dispatch("update_filters", payload);
    }, 500),
    sliderClosestDepletedTanksValue: debounce(function (val) {
      // console.log("Value ClosetDepleted change", val);
      var payload = {
        key: "distance_depleted_tanks",
        value: val,
      };
      this.$store.dispatch("update_filters", payload);
    }, 500),
    sliderClosestTerrestrialAquiferValue: debounce(function (val) {
      // console.log("Value TerrestrialAquifer change", val);
      var payload = {
        key: "distance_terrestrial_aquifer",
        value: val,
      };
      this.$store.dispatch("update_filters", payload);
    }, 500),
    sliderClosestDunkerqueValue: debounce(function (val) {
      // console.log("Value Dunkerque change", val);
      var payload = {
        key: "distance_dunkerque",
        value: val,
      };
      this.$store.dispatch("update_filters", payload);
    }, 500),
  },

  computed: {
    count_dist() {
      return this.$store.state.filters_dist_count;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title-card {
  color: #737373;
  font-weight: bold;
  text-align: left;
}
</style>

