<template>
  <div class="small">
    <line-chart
      v-if="chartDataLoaded"
      :chart-data="datacollection"
      :options="options"
    ></line-chart>
  </div>
</template>

<script>
import LineChart from "./PollutingGraph.js";
import api from "@/api.js";
import Vue from "vue";
export default {
  components: {
    LineChart,
  },
  data() {
    return {
      datacollection: null,
      chartDataLoaded: false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                min: 0,
                callback: function(value) {
                  if (value > 1000000) {
                    return value / 1e6 + " millions" ;
                  } else {
                    return value.toLocaleString();
                  }
                }
              },
            },
          ],
        },
        title: { display: true, text: "EVOLUTION DES EMISSIONS DE L'ETABLISSEMENT" },
        legend: { display: true, position: "right" },
      },
    };
  },
  async mounted() {
    this.chartDataLoaded = false;
    this.datacollection = await this.createFigure(
      this.$store.state.selectedBranchId
    );
    if (this.datacollection) {
      this.chartDataLoaded = true;
    }
  },
  watch: {
    "$store.state.selectedBranchId": async function (value) {
      this.chartDataLoaded = false;
      this.datacollection = await this.createFigure(
        value
      );
      if (this.datacollection) {
        this.chartDataLoaded = true;
      }
    }
  },
  methods: {
    createFigure: async function (sir) {
      var options = { params: { siret: sir } };
      var response = await Vue.http
        .get(api.get_url("company/polluting/data", options))
        .catch((e) => console.error("PB fetching pollutings", e));
      if (response.body[0]["labels"].length === 0) {
        return false;
      } else {
        return response.body[0];
      }
    },
  },
};
</script>

<style>
.small {
  max-width: 90%;
  margin: auto;
  margin-top: 50px;
}

#line-chart {
  height: 350px !important;
}
</style>
