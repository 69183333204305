<template>
  <b-overlay :show="show" rounded="sm" spinner-variant="primary">
    <div style="position: absolute; top: 10px; right: 10px; z-index: 100">
      <b-button id="layers-popover" variant="primary"
        ><b-icon
          icon="layers"
          aria-hidden="true"
          variant="light"
          size="1.5"
        ></b-icon>
      </b-button>
    </div>
    <div style="position: absolute; top: 55px; right: 10px; z-index: 100">
      <b-button id="cluster-popover" variant="primary"
        ><b-icon
          icon="gear"
          aria-hidden="true"
          variant="light"
          size="1.5"
        ></b-icon>
      </b-button>
    </div>
    <div style="position: absolute; top: 97px; right: 10px; z-index: 100">
      <DownloadMap />
    </div>

    <b-popover
      target="layers-popover"
      triggers="click"
      placement="leftbottom"
      title="Calques"
      text-variant="primary"
    >

      <b-tabs content-class="nav-justified nav">
        <b-tab title="Climat" active>

          <b-form-radio-group v-model="layer_climat_selected" style="margin: 10px">
            <div v-for="layer in layers_climat" :key="'climat_' + layer.name">
              <b-form-radio :value="layer.name">{{
                layer.name
              }}</b-form-radio>
            </div>
          </b-form-radio-group>
          <b-button id="button-remove-calcs" variant="danger" @click="removeCalcs()"
        ><b-icon
          icon="trash-fill"
          aria-hidden="true"
          variant="light"
          size="1.5"
        ></b-icon>
      </b-button>
        </b-tab>
        <b-tab title="Énergies" >
          <b-form-radio-group v-model="layer_energie_selected" style="margin: 10px">
            <div v-for="layer in layers_energies" :key="'energie_' + layer.name">
              <b-form-radio :value="layer.name">{{
                layer.name
              }}</b-form-radio>
            </div>
          </b-form-radio-group>
          <b-button id="button-remove-calcs-bis" variant="secondary" @click="removeCalcs()"
        ><b-icon
          icon="trash-fill"
          aria-hidden="true"
          variant="light"
          size="1.5"
        ></b-icon>
      </b-button>
        </b-tab>
      </b-tabs>
    </b-popover>

    <b-popover
      target="cluster-popover"
      triggers="click"
      placement="leftbottom"
      title="Unité du regroupement par cluster"
      text-variant="secondary"
    >
      <b-form-group
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          v-model="group"
          :options="options"
          :aria-describedby="ariaDescribedby"
          name="radios-stacked"
          stacked
        ></b-form-radio-group>
      </b-form-group>
    </b-popover>

    <div style="position: absolute; bottom: 30px; left: 20px; z-index: 100">
      <b-card
        :style="style_legend"
        :img-src="image_src"
        img-alt="Image"
        no-body
      />
    </div>

    <div style="height: 100%" ref="myMap" id="map"></div>
  </b-overlay>
</template>

<script>
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import api from "@/api.js";
import settings from "@/settings.js";
import DownloadMap from "@/components/DownloadMap.vue";

mapboxgl.accessToken =
  "pk.eyJ1IjoiamJqdWluLWFlb25jcmVhdGlvbiIsImEiOiJja3Q0YjdtaXoxMmMzMnZyMHBlbXV2bmxwIn0.Kim9-O_hS0xPi9RLpHcW6g";

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  name: "MapboxglMap",
  components: { DownloadMap },
  props: {
    msg: String,
  },
  data() {
    return {
      show: false,
      group: "co2",
      options: [
        {
          text: "Emissions de CO2 (en millions de tonnes par an)",
          value: "co2",
        },
        { text: "Nombre d'établissements", value: "nb_branch" },
      ],
      showLayers: false,
      visibleLayerIds: [],
      layer_energie_selected: "",
      layer_climat_selected: "",
      image_src: "",
      style_legend: "display: none",

      // layer_infra_selected: '',
      // This represents all GeoFeatures layers
      // Normally, to integrate a new layer and make it displays in the webapp
      // You just have to add an element to this list
      // Caution : name must refer to some geo.models:Layer.name in DB
      layers_climat: [
        {
          name: "Retrait gonflement des argiles",
          layers: [
            {
              name: "retrait_gonflement_argiles_Faible",
              color: "#eaeb8e",
            },
            {
              name: "retrait_gonflement_argiles_Moyen",
              color: "#e99a04",
            },
            {
              name: "retrait_gonflement_argiles_Fort",
              color: "#ec1907",
            },
          ],
        },
        {
          name: "Innondations par remontées de nappes",
          layers: [
            {
              name: "innondation_remontee_nappe_Faible",
              color: "#f7fbff",
            },
            {
              name: "innondation_remontee_nappe_Moyen",
              color: "#73b3d8",
            },
            {
              name: "innondation_remontee_nappe_Fort",
              color: "#08306b",
            },
          ],
        },
        {
          name: "Innondations par débordement de cours d'eau",
          layers: [
            {
              name: "innondation_debordement_eau_Faible",
              color: "#f7fbff",
            },
            {
              name: "innondation_debordement_eau_Moyen",
              color: "#73b3d8",
            },
            {
              name: "innondation_debordement_eau_Fort",
              color: "#08306b",
            },
          ],
        },
        {
          name: "Submertion marine - Hauteur d'eau",
          layers: [
            {
              name: "hauteur_eau_Faible",
              color: "#f7fbff",
            },
            {
              name: "hauteur_eau_Moyen",
              color: "#73b3d8",
            },
            {
              name: "hauteur_eau_Fort",
              color: "#08306b",
            },
          ],
        },
        {
          name: "Submertion marine - Erosion cotière",
          layers: [
            {
              name: "erosion_cotiere_inc",
              color: "#808080",
            },
            {
              name: "erosion_cotiere_rec3",
              color: "#a74f0f",
            },
            {
              name: "erosion_cotiere_rec15",
              color: "#be762e",
            },
            {
              name: "erosion_cotiere_rec05",
              color: "#f59650",
            },
            {
              name: "erosion_cotiere_rec0",
              color: "#fede9a",
            },
            {
              name: "erosion_cotiere_np",
              color: "#abfaf0",
            },
            {
              name: "erosion_cotiere_av0",
              color: "#dbef9d",
            },
            {
              name: "erosion_cotiere_av05",
              color: "#89cb61",
            },
            {
              name: "erosion_cotiere_av15",
              color: "#1ca646",
            },
            {
              name: "erosion_cotiere_av3",
              color: "#006900",
            },
          ],
        },
      ],
      layers_energies: [
        {
          name: "Réseaux électriques",
          layers: [
            {
              name: "electricity_network_tres_haute_souterraines",
              color: "#e99a04",
            },
            {
              name: "electricity_network_haute_souterraines",
              color: "#fcae1d",
            },
            {
              name: "electricity_network_tres_haute_aerienne",
              color: "#ec1907",
            },
            {
              name: "electricity_network_haute_aerienne",
              color: "#f94939",
            },
          ],
        },
        {
          name: "Réseaux d'énergie",
          layers: [
            {
              name: "energy_network_hot",
              color: "#e60000",
            },
            {
              name: "energy_network_cold",
              color: "#66ccff",
            },
          ],
        },
        {
          name: "Réseaux de gaz naturel",
          layers: [
            {
              name: "gaz_naturel_gaz",
              color: "#999966",
            },
          ],
        },
        {
          name: "Réseaux ferroviaire",
          layers: [
            {
              name: "railway_lignes_conventionnelles",
              color: "#c2c2a3",
            },
          ],
        },
        {
          name: "Territoires d'industrie",
          layers: [
            {
              name: "territory_industry",
              color: "#a6a6a6",
            },
          ],
        },
        {
          name: "Aquifères",
          layers: [
            {
              name: "aquifer_terrestrial",
              color: "#a6a6a6",
            },
            {
              name: "aquifer_submarine",
              color: "#a6a6a6",
            },
          ],
        },
        {
          name: "Maille IRIS",
          layers: [
            {
              name: "iris_iris",
              color: "#808080",
            },
          ],
        },
      ],
    };
  },
  watch: {
    "$store.state.show_overlay": function (value) {
      this.show = value;
    },
    "$store.state.zoom_nclicks": function () {
      // val can be passed to function but is no more used there (was in a console.log)
      // So removed to pass No-Used Variable error
      var selected_siret = this.$store.state.selectedBranchId;
      var coordinates = [
        this.$store.state.branches_by_siret[selected_siret].longitude,
        this.$store.state.branches_by_siret[selected_siret].latitude,
      ];
      // console.log("zoom nclicks ", value);
      this.map.jumpTo({ center: coordinates, zoom: 8 });
    },
    "$store.state.sirets_count": function (value, oldValue) {
      // console.log("Watcher store.state.sirets", value)
      var self = this;
      if (self.$store.state.filters_update_map_data){
        var hour = self.$store.state.profile;
        if (value != oldValue) {
          let mapSource = self.map.getSource("branches");
          if (mapSource) {
            let data = this.sirets_to_geojson(self.$store.state.sirets);
            mapSource.setData(data);
          }
        } else if (hour != "") {
          let mapSource = self.map.getSource("branches");
          if (mapSource) {
            let data = this.sirets_to_geojson(self.$store.state.sirets);
            mapSource.setData(data);
          }
        }
      }
    },
    group: function () {
      this.init_map();
      this.map.on("load", () => {
        let mapSource = this.map.getSource("branches");
        if (mapSource) {
          let data = this.sirets_to_geojson(this.$store.state.sirets);
          mapSource.setData(data);
          this.map.jumpTo({
            center: this.$store.state.center,
            zoom: this.$store.state.zoom,
          });
        }
      });
    },
    "$store.state.activeBranchId": function (value, oldValue) {
      var self = this;

      if (value != oldValue || value === null) {
        if (self.active_marker != null) {
          self.active_marker.remove();
          self.active_popup.remove();
          // self.map.removeLayer(self.active_marker)
          // self.map.removeLayer(self.active_popup)
        }
      }
      if (value !== null && value !== false) {
        var branch = self.$store.state.branches_by_siret[value];
        var coordinates = [branch.longitude, branch.latitude];
        self.active_marker = new mapboxgl.Marker({ color: "#187db6" })
          .setLngLat(coordinates)
          .addTo(self.map);
        // self.active_marker = L.marker([self.markers[value].position.lat, self.markers[value].position.lng], {icon: mapindus_active_marker_icon, riseOnHover: true})
        self.active_marker.addTo(self.map);
        self.active_popup = new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(self.compute_popup(branch))
          .addTo(self.map);
      }
    },
    layer_energie_selected: function (value) {
      console.log(value);
      if (value != ""){
        var self = this
        this.image_src = ""
        this.style_legend = "display: none"
        if (self.visibleLayerIds.length > 0) {
            for (var lay of self.visibleLayerIds) {
              console.log("remove layer", lay)
              self.map.removeLayer(lay);
            }
            self.visibleLayerIds = [];
          }
        for (let i in this.layers_energies) {
          if (this.layers_energies[i].name == value) {
            this.showLayer(this.layers_energies[i]);
            break;
          }
        }
        self.layer_climat_selected =  ""
      }
    },
    layer_climat_selected: function (value) {
      console.log(value);
      if (value !== ""){
        var self = this
        this.image_src = require(`@/assets/${value}.png`)
        this.style_legend = "display: block"
        if (self.visibleLayerIds.length > 0) {
            for (var lay of self.visibleLayerIds) {
              console.log("remove layer", lay)
              self.map.removeLayer(lay);
            }
            self.visibleLayerIds = [];
          }
        for (let i in this.layers_climat) {
          if (this.layers_climat[i].name == value) {
            this.showLayer(this.layers_climat[i]);
            break;
          }
        }
        this.layer_energie_selected = ""
      }
    }
  },
  mounted: async function () {
    console.log("Initialising map")
    this.init_map();
    console.log("Map initialized")
  },
  methods: {
    removeCalcs: function(){
      var self = this;
      if (self.visibleLayerIds.length > 0) {
          for (let lay of self.visibleLayerIds) {
            self.map.removeLayer(lay);
          }
          self.visibleLayerIds = [];
        }
      self.layer_energie_selected = ""
      self.layer_climat_selected =  ""
      self.style_legend = "display: none"
    },
    showLayer: function (layers) {
      let self = this;
      var layers_calcs = layers.layers
      var layers_name = layers.name;
      const setLayer = function (layer) {
        // the second argument: "unclustered-point" is the layer below which to place
        // this one... see here: https://docs.mapbox.com/mapbox-gl-js/example/geojson-layer-in-stack/
        console.log("Try to set layer ", layer)
        self.map.addLayer(
          {
            id: layer.name, // Layer ID
            type: "fill",
            source: layer.name,
            "source-layer": "public.function_source",
            paint: {
              "fill-color": layer.color,
              "fill-opacity": 0.25,
            },
          },
          "unclustered-point"
        );
        self.visibleLayerIds.push(layer.name);
      };
      const setCircleLayer = function (layer) {
        self.map.addLayer(
          {
            id: layer.name, // Layer ID
            type: "circle",
            source: layer.name,
            "source-layer": "public.function_source",
            paint: {
              "circle-color": layer.color,
              "circle-radius": 4,
              "circle-stroke-width": 0,
            },
          },
          "unclustered-point"
        );
        self.visibleLayerIds.push(layer.name);
      };
      const setLineLayer = function (layer) {
        self.map.addLayer(
          {
            id: layer.name, // Layer ID
            type: "line",
            source: layer.name,
            "source-layer": "public.function_source",
            paint: {
              "line-color": layer.color,
              "line-width": 1,
            },
          },
          "unclustered-point"
        );
        self.visibleLayerIds.push(layer.name);
      };

      for (let layer of layers_calcs) {
        if (layers_name === "Réseaux d'énergie") {
          setCircleLayer(layer);
        } else if (layers_name === "Réseaux ferroviaire"){
          setLineLayer(layer);
        } else {
          setLayer(layer);
        }
      }

    },
    toggleLayers: function () {
      console.log("toggleLayers");
      this.showLayers = !this.showLayers;
    },
    sirets_to_geojson: function (sirets) {
      let self = this;
      // convert data stored as sirets to geojson for map source
      var features = [];
      for (var siret of sirets) {
        var feature = {
          type: "Feature",
          properties: {
            siret: siret,
            quantity: self.$store.state.branches_by_siret[siret].quantity,
          },
          geometry: {
            type: "Point",
            coordinates: [
              self.$store.state.branches_by_siret[siret].longitude,
              self.$store.state.branches_by_siret[siret].latitude,
            ],
          },
        };
        features.push(feature);
      }
      var data = {
        type: "FeatureCollection",
        features: features,
      };
      self.$store.state.show_overlay = false;
      return data;
    },
    init_map: function () {
      var self = this;
      this.map = new mapboxgl.Map({
        container: "map", // container ID
        style: "mapbox://styles/mapbox/light-v10", // style URL
        center: [1, 46.870576], // starting position [lng, lat]
        zoom: 5, // starting zoom
        preserveDrawingBuffer: true,
        transformRequest: (url, resourceType) => {
          if (
            resourceType === "Source" &&
            url.indexOf(api.get_url("company2/") > -1)
          ) {
            if (self.$store.getters.is_authenticated === true) {
              let token = self.$store.state.access_token;
              return {
                url: url,
                headers: {
                  Authorization: `Bearer ${token}`,
                  Accept: "application/json",
                },
              };
            }
          }
        },
      });
      this.map.on("load", function () {
        self.add_map_sources_and_layers();
      });
    },
    compute_popup: function (branch) {
      return `<b>${branch.corporate_name}</b><br>SIRET : ${branch.siret}<br>${branch.city__name}`;
    },
    add_map_sources_and_layers: async function () {
      var self = this;

      // console.log("fetch with vue resource")
      // let response = await this.$http.get(api.get_url("company2/all-fast-geojson"))
      // let sirets = this.$store.state.sirets
      var data = {
        type: "FeatureCollection",
        features: [],
      };

      self.map.addSource("branches", {
        type: "geojson",
        // data: api.get_url("company2/all-fast-geojson"),
        // data: response.data,
        data: data,
        cluster: true,
        clusterMaxZoom: 10, // Max zoom to cluster points on
        // clusterMinPoints: 10, // Min number of points to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        clusterProperties: { quantity: ["+", ["get", "quantity"]] },
      });

      //
      // functions used if the group is by co2
      //
      const markers = {};
      let markersOnScreen = {};
      // code for creating an SVG donut chart from feature properties
      function createDonutChart(props, min_val, max_val, stroke_width) {
        const min_valt = min_val / 1000;
        const max_valt = max_val / 1000;
        const quantity = props.quantity / 1000; // from kg to tonnes
        const fontSize = 9;
        var r = 0;
        // If no emmission and no cluster : size = 10
        // if ((stroke_width === 0) & (quantity === 0)) {
        //   console.log("Coucou")
        //   r = 10;
        // }
        // If no emmission in cluster : size = 23
        if (quantity === 0) {
          r = 23;
        }
        // Else size depends on quantity (10 intervals from min_val to max_val)
        // min_val and max_val are dynamic with zoom and filters, corresponding to a cluster quantity (max or min) and not a branch quantity
        else if (quantity > 0) {
          var j = 27;
          if (min_valt == max_valt) {
            r = j;
          } else {
            for (
              let i = min_valt;
              i < max_valt;
              i = i + Math.round((max_valt - min_valt) / 10)
            ) {
              if (
                (quantity >= i) &
                (quantity < i + Math.round((max_valt - min_valt) / 10))
              ) {
                r = j;
                break;
              }
              j = j + 4;
            }
          }
        }

        const color =
          quantity >= 1000000
            ? "#0c3d5a"
            : quantity >= 1000
            ? "#125c87"
            : quantity >= 1
            ? "#177bb5"
            : quantity > 0
            ? "#1d9ae2"
            : "#34a4e5";
        // /100 to have number like 15.25
        // Mt = millions de tonnes
        const text = `${Math.round(quantity / 10000) / 100} Mt`;
        const r0 = Math.round(r * 0.6);
        const w = r * 2;

        let html = `<div>
          <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

        if (stroke_width === 0) {
          html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="${color}" stroke-width="${stroke_width}"/>
            <text dominant-baseline="central" transform="translate(${r}, ${r})" style="fill: white;">
            ${text}
            </text>
            </svg>
            </div>`;
        } else {
          html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="#ffffff" stroke="${color}" stroke-width="${stroke_width}"/>
            <text dominant-baseline="central" transform="translate(${r}, ${r})" style="fill: black;">
            ${text}
            </text>
            </svg>
            </div>`;
        }

        const el = document.createElement("div");
        el.innerHTML = html;
        return el.firstChild;
      }

      function updateMarkers() {
        const newMarkers = {};
        const features = self.map.querySourceFeatures("branches");

        // calculate the min and max values of clusters to make sizes dynamic
        var min_val = -1;
        var max_val = -1;
        for (const feature of features) {
          const props = feature.properties;
          if (!props.cluster) continue;
          if (props.quantity > max_val) max_val = props.quantity;
          if (
            ((props.quantity < min_val) | (min_val === -1) | (min_val === 0)) &
            (props.quantity !== 0)
          )
            min_val = props.quantity;
        }
        // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
        // and add it to the map if it's not there already
        for (const feature of features) {
          const coords = feature.geometry.coordinates;
          const props = feature.properties;
          if (!props.cluster) {
            var id = props.siret;
            var stroke_width = 0;
          } else {
            id = props.cluster_id;
            stroke_width = 2;
          }


          let marker = markers[id];
          if (!marker) {
            const el = createDonutChart(props, min_val, max_val, stroke_width);
            marker = markers[id] = new mapboxgl.Marker({
              element: el,
            }).setLngLat(coords);
          }
          newMarkers[id] = marker;

          if (!markersOnScreen[id]) {
            marker.addTo(self.map);
          }
        }
        // for every marker we've added previously, remove those that are no longer visible
        for (const id in markersOnScreen) {
          if (!newMarkers[id]) {
            markersOnScreen[id].remove();
          }
        }
        markersOnScreen = newMarkers;
      }

      if (this.group === "nb_branch") {
        self.map.addLayer({
          id: "clusters",
          type: "circle",
          source: "branches",
          filter: ["==", "cluster", true],
          paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#1d9ae2",
              100,
              "#1a8acb",
              750,
              "#187db6",
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              10,
              100,
              15,
              750,
              20,
            ],
          },
        });

        self.map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "branches",
          filter: ["==", "cluster", true],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
          paint: {
            "text-color": "#ffffff",
          },
        });

        self.map.addLayer({
          id: "unclustered-point",
          type: "circle",
          source: "branches",
          filter: ["!=", "cluster", true],
          paint: {
            "circle-color": "#34a4e5",
            "circle-radius": 5,
            "circle-stroke-width": 0,
          },
        });
      } else {
        self.map.addLayer({
          id: "unclustered-point",
          type: "circle",
          source: "branches",
          filter: ["!=", "cluster", true],
          paint: {
            "circle-color": [
              "case",
              [
                "all",
                [">", ["get", "quantity"], 0],
                ["<", ["get", "quantity"], 1000],
              ],
              "#1d9ae2",
              [
                "all",
                [">=", ["get", "quantity"], 1000],
                ["<", ["get", "quantity"], 1000000],
              ],
              "#177bb5",
              [
                "all",
                [">=", ["get", "quantity"], 1000000],
                ["<", ["get", "quantity"], 1000000000],
              ],
              "#125c87",
              [">=", ["get", "quantity"], 1000000000],
              "#0c3d5a",
              "#34a4e5",
            ],
            "circle-radius": 5,
            "circle-stroke-width": 0,
            // 'circle-stroke-color': '#fff'
          },
        });

        // after the GeoJSON data is loaded, update markers on the screen on every frame
        self.map.on("render", () => {
          if (!self.map.isSourceLoaded("branches")) return;
          updateMarkers();

        });
      }

      self.map.on("click", "clusters", (e) => {
        const features = self.map.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        self.map
          .getSource("branches")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;
            self.map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      self.map.on("click", "unclustered-point", (e) => {
        const siret = e.features[0].properties.siret;
        this.$store.dispatch("select_branch", siret);
        // Trick to hide sidebar if a branch is selected on map
        this.$store.state.sidebar_toggled =
          this.$store.state.sidebar_toggled + 1;
      });

      // When a click event occurs on a feature in
      // the unclustered-point layer, open a popup at
      // the location of the feature, with
      // description HTML from its properties.
      var hover_popup = null;
      self.map.on("mouseenter", "unclustered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const siret = e.features[0].properties.siret;
        var branch = self.$store.state.branches_by_siret[siret];

        // Ensure that if the map is zoomed out such that
        // multiple copies of the feature are visible, the
        // popup appears over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        hover_popup = new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(self.compute_popup(branch))
          .addTo(self.map);
      });
      self.map.on("mouseout", "unclustered-point", () => {
        hover_popup.remove();
      });

      self.map.on("mouseenter", "clusters", () => {
        self.map.getCanvas().style.cursor = "pointer";
      });
      self.map.on("mouseleave", "clusters", () => {
        self.map.getCanvas().style.cursor = "";
      });
      self.map.on("moveend", async function () {
        await self.handle_view_change();
      });
      self.map.on("zoomend", async function () {
        await self.handle_view_change();
      });

      ////////////////////////////// Integration du serveur de tuiles /////////////////////
      self.layers_energies.forEach(function (layer) {
        layer.layers.forEach((l) => {
          let tiles_url = `${settings.tiles.root_url}/rpc/public.function_source/{z}/{x}/{y}.pbf?layer_name=${l.name}`;
          self.map.addSource(l.name, {
            type: "vector",
            tiles: [tiles_url],
          });
        });
      });
      self.layers_climat.forEach(function (layer) {
        layer.layers.forEach((l) => {
          let tiles_url = `${settings.tiles.root_url}/rpc/public.function_source/{z}/{x}/{y}.pbf?layer_name=${l.name}`;
          self.map.addSource(l.name, {
            type: "vector",
            tiles: [tiles_url],
          });
        });
      });
      ////////////////////////////// Integration du serveur de tuiles /////////////////////
    },
    handle_view_change: debounce(async function () {
      var self = this;
      var bounds = self.map.getBounds();
      var bbox = { ne: bounds.getNorthEast(), sw: bounds.getSouthWest() };
      await self.$store.dispatch("update_map_bounds", bbox);
      var coordinates = [self.map.getCenter().lng, self.map.getCenter().lat];
      this.$store.state.center = coordinates;
      this.$store.state.zoom = self.map.getZoom();
    }, 500),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#button-remove-calcs{
margin-left: 13em;
}
#button-remove-calcs-bis{
margin-left: 13em;
}
</style>
