
var LoadingMixin = {
    data: function(){
      return {"loading": false}
    },
    methods: {
      "start_loading": function(){console.log("start loading"); this.loading = true},
      "stop_loading": function(){console.log("stop loading"); this.loading = false},
    }
}

export default {LoadingMixin}
