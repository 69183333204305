<template>
  <div>
    <b-navbar toggleable="lg" type="dark" :sticky="true" style="">
      <b-button variant="outline-light" size="sm" class="mr-1 ml-1 mt-3 mb-3" v-b-toggle.sidebar_left
        @click="(payload) => this.selectBranch({ siret: false })"><b-icon icon="sliders" aria-hidden="true"
          variant="primary" size="2">
        </b-icon><b-badge variant="secondary">{{ n_active_filters }}</b-badge></b-button>

      <div v-for="filter in filters_values" :key="filter.key">
        <div style="margin-right: 3em; color: #187db6" v-if="filter_displays(filter)"
          v-html="filter_html_transform(filter)"></div>
      </div>
    </b-navbar>

    <b-sidebar sidebar-class="sidebar-height" id="sidebar_left" ref="sidebar_left" title="Filtres" shadow width="50%"
      text-variant="primary">
      <div class="px-3 py-2 title" v-if="count == 1">
        <h4>{{ count }} établissement</h4>
      </div>
      <div class="px-3 py-2 title" v-if="count > 1">
        <h4>{{ count.toLocaleString() }} établissements</h4>
      </div>
      <div class="alert" v-if="count == 0">
        <b-alert variant="secondary" show>Aucun établissement ne correspond à votre recherche. <br>Veuillez supprimer
          quelques filtres.</b-alert>
      </div>

      <div class="px-3 py-2">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-geo variant="outline-light" class="title-card">▽ GÉOGRAPHIQUES
              <b-badge>{{ count_geo }}</b-badge></b-button>
          </b-card-header>
          <!-- Geographic filter part -->
          <b-collapse id="accordion-geo" role="tabpanel">
            <b-card-body>
              <!-- Region selector -->
              <b-form-group id="group-select-reg" label="Région" label-for="select-reg" content-cols="8" label-cols="4">
                <multiselect id="select-reg" v-model="dropdownRegionValue" :options="dropdownRegionsOptions"
                  :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                  placeholder="Sélectionnez une région ..." label="name" track-by="name" :preselect-first="false"
                  select-label="" selected-label="✓" deselect-label="" :limit="3" :limitText="
                    (count) =>
                      count === 1 ? `${count} autre` : `${count} autres`
                  ">
                </multiselect>
              </b-form-group>
              <!-- State selector -->
              <b-form-group id="group-select-state" label="Département" label-for="select-state" content-cols="8"
                label-cols="4">
                <multiselect id="select-state" v-model="dropdownStateValue" :options="dropdownStatesOptions"
                  :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                  placeholder="Sélectionnez un département ..." label="name" track-by="name" :preselect-first="false"
                  select-label="" selected-label="✓" deselect-label="" :limit="3" :limitText="
                    (count) =>
                      count === 1 ? `${count} autre` : `${count} autres`
                  ">
                </multiselect>
              </b-form-group>
              <!-- City selector -->
              <b-form-group id="group-select-city" label="Ville" label-for="select-city" content-cols="8" label-cols="4">
                <multiselect id="select-city" v-model="dropdownCityValue" :options="dropdownCitiesOptions"
                  :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                  placeholder="Sélectionnez une ville ..." label="name" track-by="name" :preselect-first="false"
                  select-label="" selected-label="✓" deselect-label="" :limit="3" :limitText="
                    (count) =>
                      count === 1 ? `${count} autre` : `${count} autres`
                  ">
                </multiselect>
              </b-form-group>
              <!-- ZIP selector -->
              <b-form-group id="group-select-zip" label="Zone industrialo-portuaire" label-for="select-zip"
                content-cols="8" label-cols="4">
                <multiselect id="select-zip" v-model="dropdownZipValue" :options="dropdownZipsOptions" :multiple="true"
                  :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                  placeholder="Sélectionnez une zone ..." label="name" track-by="name" :preselect-first="false"
                  select-label="" selected-label="✓" deselect-label="" :limit="3" :limitText="
                    (count) =>
                      count === 1 ? `${count} autre` : `${count} autres`
                  ">
                </multiselect>
              </b-form-group>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- Admin filter part -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-comp variant="outline-light" class="title-card">▽ ADMINISTRATIFS
              <b-badge>{{ count_admin }}</b-badge></b-button>
          </b-card-header>
          <b-collapse id="accordion-comp" role="tabpanel">
            <b-card-body>
              <!-- Activity selector -->
              <b-form-group id="group-select-act" label="Secteur d'activité (NAF)" label-for="select-act" content-cols="8"
                label-cols="4">
                <multiselect id="select-act" v-model="dropdownActivitiesValue" :options="dropdownActivitiesOptions"
                  :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                  placeholder="Sélectionnez une activité ..." label="name" track-by="name" :preselect-first="false"
                  select-label="" selected-label="✓" deselect-label="" :limit="3" :limitText="
                    (count) =>
                      count === 1 ? `${count} autre` : `${count} autres`
                  ">
                </multiselect>
                <br />
                <b-form-checkbox id="checkbox-act" @input="(checked) => preselectActivities(checked)" value="preselected"
                  unchecked-value="not_preselected" checked="preselected">
                  Activités présélectionnées par l'ADEME
                </b-form-checkbox>
              </b-form-group>
              <br />
              <b-form-group id="group-select-act-nce" label="Secteur d'activité (NCE)" label-for="select-act-nce"
                content-cols="8" label-cols="4">
                <multiselect id="select-act-nce" v-model="dropdownActivitiesNceValue"
                  :options="dropdownActivitiesNceOptions" :multiple="true" :close-on-select="true"
                  :clear-on-select="false" :preserve-search="true" placeholder="Sélectionnez une activité ..."
                  label="name" track-by="name" :preselect-first="false" select-label="" selected-label="✓"
                  deselect-label="" :limit="3" :limitText="
                    (count) =>
                      count === 1 ? `${count} autre` : `${count} autres`
                  ">
                </multiselect>
              </b-form-group>
              <br />
              <b-form-group id="group-select-act-ind" label="Secteur d'activité (secteur industrie manufacturière)"
                label-for="select-act-ind" content-cols="8" label-cols="4">
                <multiselect id="select-act" v-model="dropdownActivitiesIndValue" :options="dropdownActivitiesIndOptions"
                  :multiple="true" :close-on-select="true" :clear-on-select="false" :preserve-search="true"
                  placeholder="Sélectionnez une activité ..." label="name" track-by="name" :preselect-first="false"
                  select-label="" selected-label="✓" deselect-label="" :limit="3" :limitText="
                    (count) =>
                      count === 1 ? `${count} autre` : `${count} autres`
                  ">
                </multiselect>
              </b-form-group>
              <WorkforceFilter />
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- "Audits et certifications" filter part -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-aucert variant="outline-light" class="title-card">▽ AUDITS ET
              CERTIFICATONS <b-badge>{{ count_certif }}</b-badge></b-button>
          </b-card-header>
          <b-collapse id="accordion-aucert" role="tabpanel">
            <b-card-body>
              <b-form-checkbox id="checkbox-ppe" v-model="ppeSelectedValue" value="selected" unchecked-value="unselected">
                L'entreprise dispose d'un PPE TURPE
              </b-form-checkbox>
              <br />
              <b-form-group id="group-select-cert" label="Audits et certifications" label-for="select-cert"
                content-cols="8" label-cols="4">
                <multiselect id="select-cert" v-model="dropdownCertificationValue"
                  :options="dropdownCertificationsOptions" :multiple="true" :close-on-select="true"
                  :clear-on-select="false" :preserve-search="true" placeholder="Sélectionnez une option ..." label="name"
                  track-by="name" :preselect-first="false" select-label="" selected-label="✓" deselect-label="">
                </multiselect>
              </b-form-group>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- Energy filter part -->
        <b-card no-body class="mb-1">
          <b-card-header id="ener-header" header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-ener variant="outline-light" class="title-card">▽ CONSOMMATIONS
              D'ÉNERGIES <b-badge>{{ count_conso }}</b-badge></b-button>
          </b-card-header>
          <b-tooltip target="ener-header" triggers="hover" placement="left" variant="light">
            Ce filtre permet d'afficher les établissements consommant
            au moins une des énergies sélectionnées, dans l'intervalle
            de valeurs sélectionné.
          </b-tooltip>
          <b-collapse id="accordion-ener" role="tabpanel">
            <b-row class="justify-content-md-center">
              <b-form-group id="group-radio-ener" v-slot="{ ariaDescribedby }">
                <b-form-radio-group v-model="radioEnergyValue" :options="radioEnergieUnitOptions"
                  :aria-describedby="ariaDescribedby" name="radio-ener"></b-form-radio-group>
              </b-form-group>
            </b-row>
            <div slot="" v-for="filter in energyFilters" :key="filter.divId">
              <b-row class="justify-content-md-center">
                <b-col cols="6">
                  <multiselect :id="filter.dropdownId" v-model="filter.dropdownValueVariable"
                    :options="dropdownEnergiesOptions" :multiple="false" :close-on-select="true" :clear-on-select="false"
                    :preserve-search="true" placeholder="Sélectionnez une option ..." label="label" track-by="label"
                    :preselect-first="false" select-label="" selected-label="✓" deselect-label="">
                  </multiselect>
                </b-col>
                <b-col cols="3">
                  <b-input-group>
                    <b-form-input type="number" debounce="500" v-model="filter.minValue"
                      placeholder="De ..."></b-form-input>
                    <b-input-group-append>
                      <b-input-group-text class="bg-transparent font-weight-bold" v-if="filter.dropdownValueVariable">
                        {{
                          filter.dropdownValueVariable[
                          radioEnergyValue + "_unit"
                          ]
                        }}
                      </b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col cols="3">
                  <b-input-group>
                    <b-form-input type="number" debounce="500" v-model="filter.maxValue"
                      placeholder="à ..."></b-form-input>
                    <b-input-group-append>
                      <b-input-group-text class="bg-transparent font-weight-bold" v-if="filter.dropdownValueVariable">
                        {{
                          filter.dropdownValueVariable[
                          radioEnergyValue + "_unit"
                          ]
                        }}
                      </b-input-group-text>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <br />
            </div>
          </b-collapse>
        </b-card>
        <!-- Polluting filter part -->
        <C02Filter />
        <!-- Distances filter part -->
        <DistanceFilters />
        <!-- Branches filter part -->
        <FileImport />
      </div>
    </b-sidebar>
  </div>
</template>


<script>
import { Multiselect } from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { mapMutations } from "vuex";
import DistanceFilters from "@/components/DistanceFilters.vue";
import C02Filter from "@/components/C02Filter.vue";
import WorkforceFilter from "@/components/WorkforceFilter.vue";
import FileImport from "@/components/FileImport.vue";

var isNumber = function (string) {
  if (string === "") {
    return false;
  } else {
    return !isNaN(string);
  }
};

var energyFilterIsActionable = function (itemFilter) {
  // console.log(
  //   itemFilter.id,
  //   itemFilter.dropdownValueVariable,
  //   isNumber(itemFilter.maxValue),
  //   isNumber(itemFilter.minValue)
  // );
  if (
    itemFilter.dropdownValueVariable !== "" &&
    itemFilter.dropdownValueVariable &&
    isNumber(itemFilter.maxValue) &&
    isNumber(itemFilter.minValue)
  ) {
    return true;
  } else {
    return false;
  }
};

export default {
  components: {
    Multiselect,
    DistanceFilters,
    FileImport,
    C02Filter,
    WorkforceFilter
  },
  name: "Filters",
  data() {
    // let selectedItemIndex = this.$store.state.filteredBranches;
    var nEnergyFilters = 3;
    var energyFilters = [];
    for (let i = 0; i < nEnergyFilters; i++) {
      energyFilters.push({
        id: i,
        divId: "FilterEnergy" + i,
        dropdownValueVariable: null,
        dropdownId: "dropdownEnergy" + i,
        minValue: "",
        maxValue: "",
      });
    }
    return {
      dropdownRegionValue: [],
      dropdownStateValue: [],
      dropdownCityValue: [],
      dropdownZipValue: [],
      dropdownZipsOptions: [
        { name: "Bordeaux", value: ['33063'] },
        { name: "Dunkerque", value: ["59183"] },
        { name: "Fos-Sur-Mer - Marseille", value: ["13039", "13055"] },
        { name: "La Rochelle", value: ['17300'] },
        { name: "Le Havre", value: ["76351", "76035", "76341", "76533"] },
        { name: "Nantes - Saint-Nazaire", value: ["44109", "44184"] },
        { name: "Port de Strasbourg", value: ["67482"] },
        { name: "Port-Jérome-sur-Seine", value: ["76476", "76384", "76592", "76330"] },
        { name: "Rouen", value: ["76540", "76498", "76322", "76497", "76319", "76157", "76681", "76575", "76484"] },
        { name: "Vallée de la Chimie - Lyon", value: ["69123", "69152", "69199", "69100", "69276", "69260", "69296", "69133", "69294", "69096", "69297", "69091", "38087", "69118"] },
      ],
      dropdownActivitiesNceValue: [],
      dropdownActivitiesIndValue: [],
      dropdownActivitiesValue: this.$store.state.companyActivities.filter(
        (activity) => activity.preselected
      ),
      energyFilters: energyFilters,
      dropdownCertificationValue: [],
      dropdownCertificationsOptions: [
        { name: "Audit énergétique", language: "Audit énergétique" },
        { name: "Certifcation ISO 50001", language: "Certifcation ISO 50001" },
      ],
      radioEnergieUnitOptions: [
        { text: "Volume", value: "volume" },
        { text: "Coût", value: "cost" },
      ],
      radioEnergyValue: "volume",
      ppeSelectedValue: "unselected"
    };
  },
  watch: {
    dropdownCertificationValue: function (val) {
      var filternames = [];
      val.forEach((v) => filternames.push(v.name));
      this.$store.dispatch("update_filters", {
        key: "audit_certif",
        value: filternames,
      });
    },
    radioEnergyValue: function () {
      // val can be passed to function but is no more used there (was in a console.log)
      // So removed to pass No-Used Variable error
      this.energyFilters.forEach(function (filter) {
        filter.dropdownValueVariable = "";
        filter.minValue = "";
        filter.maxValue = "";
      });
    },
    dropdownRegionValue: function (val) {
      var newFiltersId = [];
      val.forEach((v) => newFiltersId.push(v.id));
      // console.log("Value region change", newFiltersId);
      this.$store.dispatch("update_filters", {
        key: "region_id",
        value: newFiltersId,
      });
    },
    dropdownActivitiesValue: function (val) {
      // console.log("Value activitie change", val);
      var newFiltersId = [];
      val.forEach((v) => newFiltersId.push(v.id));
      var payload = {
        key: "activity_id",
        value: newFiltersId,
      };
      this.$store.dispatch("update_filters", payload);
    },
    dropdownActivitiesNceValue: function (val) {
      // console.log("Value activitie change", val);
      var newFiltersId = [];
      val.forEach((v) => newFiltersId.push(v.id));
      var payload = {
        key: "activity_nce_id",
        value: newFiltersId,
      };
      this.$store.dispatch("update_filters", payload);
    },
    dropdownActivitiesIndValue: function (val) {
      // console.log("Value activitie change", val);
      var newFiltersId = [];
      val.forEach((v) => newFiltersId.push(v.id));
      var payload = {
        key: "activity_ind_id",
        value: newFiltersId,
      };
      this.$store.dispatch("update_filters", payload);
    },
    ppeSelectedValue: function (val) {
      var payload = {
        key: "ppe",
        value: val,
      }
      this.$store.dispatch("update_filters", payload);
    },
    dropdownStateValue: function (val) {
      // console.log("Value state change", val);
      var newFiltersId = [];
      val.forEach((v) => newFiltersId.push(v.id));
      var payload = {
        key: "state_id",
        value: newFiltersId,
      };
      this.$store.dispatch("update_filters", payload);
    },
    dropdownCityValue: function (val) {
      // console.log("Value city change", val);
      if (this.dropdownZipValue) {
        var newFiltersId = [];
        newFiltersId = [];
        this.dropdownZipValue.forEach(function (val) {
          val.value.forEach(function (city_id) {
            newFiltersId.push(city_id)
          }
          )
        })
      }
      else {
        newFiltersId = [];
      }
      val.forEach((v) => newFiltersId.push(v.id));
      var payload = {
        key: "city_id",
        value: newFiltersId,
      };
      this.$store.dispatch("update_filters", payload);
    },
    dropdownZipValue: function (vals) {
      if (this.dropdownCityValue) {
        var city_ids = [];
        this.dropdownCityValue.forEach((v) => city_ids.push(v.id));
      }
      else {
        city_ids = [];
      }
      vals.forEach(function (val) {
        val.value.forEach(function (city_id) {
          city_ids.push(city_id)
        }
        )
      })
      var payload = {
        key: "city_id",
        value: city_ids,
      };
      this.$store.dispatch("update_filters", payload);
    },
    energyFilters: {
      handler(val) {
        var self = this;
        var new_energy_filter_value = [];
        var count = 0
        val.forEach(function (itemFilter) {
          if (energyFilterIsActionable(itemFilter)) {
            var filter = {
              label: itemFilter.dropdownValueVariable.label,
              unit: self.radioEnergyValue,
              value: [itemFilter.minValue, itemFilter.maxValue],
            };
            new_energy_filter_value.push(filter);
          } else {
            if (
              itemFilter.dropdownValueVariable !== "" &&
              itemFilter.dropdownValueVariable
            ) {
              if (self.radioEnergyValue === "volume") {
                console.log(itemFilter)
                console.log(self.$store.state.energies_min_max_volume)
                itemFilter.minValue =
                  self.$store.state.energies_min_max_volume[
                  itemFilter.dropdownValueVariable.label
                  ]["min"];
                itemFilter.maxValue =
                  self.$store.state.energies_min_max_volume[
                  itemFilter.dropdownValueVariable.label
                  ]["max"];
                count = count + 1;
              } else {
                itemFilter.minValue =
                  self.$store.state.energies_min_max_cost[
                  itemFilter.dropdownValueVariable.label
                  ]["min"];
                itemFilter.maxValue =
                  self.$store.state.energies_min_max_cost[
                  itemFilter.dropdownValueVariable.label
                  ]["max"];
                count = count + 1;
              }
            } else {
              itemFilter.minValue = "";
              itemFilter.maxValue = "";
            }
          }
        });
        var payload = {
          key: "energy",
          value: new_energy_filter_value,
        };
        if (count === 0) {
          this.$store.dispatch("update_filters", payload);
        }
      },
      deep: true,
    },
    "$store.state.sidebar_toggled": function () {
      this.$refs.sidebar_left.hide();
    }
  },
  computed: {
    count() {
      return this.$store.state.sirets_count;
    },
    count_geo() {
      return this.$store.state.filters_geo_count;
    },
    count_admin() {
      return this.$store.state.filters_admin_count;
    },
    count_conso() {
      return this.$store.state.filters_conso_count;
    },
    count_certif() {
      return this.$store.state.filters_certif_count;
    },
    filters_values() {
      var filters = [
        { key: "Activités", value: this.dropdownActivitiesValue },
        { key: "Activités NCE", value: this.dropdownActivitiesNceValue },
        { key: "Activités industrie", value: this.dropdownActivitiesIndValue },
        { key: "Régions", value: this.dropdownRegionValue },
        { key: "Départements", value: this.dropdownStateValue },
        { key: "Villes", value: this.dropdownCityValue },
      ];
      return filters;
    },
    n_active_filters() {
      return this.$store.state.filters_all_count
    },
    dropdownEnergiesOptions() {
      return this.$store.state.companyEnergies;
    },
    dropdownRegionsOptions() {
      return this.$store.state.franceRegions;
    },
    selectedRegionIds() {
      var selected = [];
      this.dropdownRegionValue.forEach((reg) => selected.push(reg.id));
      // console.log("Selected Regions ID:", selected);
      return selected;
    },
    dropdownStatesOptions() {
      if (this.selectedRegionIds.length > 0) {
        // console.log(this.selectedRegionIds);
        // this.$store.state.franceStates.forEach((state) => // console.log(state));
        return this.$store.state.franceStates.filter((state) =>
          this.selectedRegionIds.includes(state.region)
        );
      } else {
        return this.$store.state.franceStates;
      }
    },
    selectedStateIds() {
      var selected = [];
      if (this.dropdownStateValue !== undefined) {
        this.dropdownStateValue.forEach((state) => selected.push(state.id));
      }
      return selected;
    },
    dropdownCitiesOptions() {
      if (this.selectedStateIds.length > 0) {
        return this.$store.state.franceCities.filter((city) =>
          this.selectedStateIds.includes(city.state)
        );
      } else if (this.selectedRegionIds.length > 0) {
        return this.$store.state.franceCities.filter((city) =>
          this.selectedRegionIds.includes(city.state__region)
        );
      } else {
        return this.$store.state.franceCities;
      }
    },
    dropdownActivitiesOptions() {
      return this.$store.state.companyActivities;
    },
    dropdownActivitiesNceOptions() {
      return this.$store.state.companyActivitiesNCE;
    },
    dropdownActivitiesIndOptions() {
      return this.$store.state.companyActivitiesInd;
    }
  },
  methods: {
    ...mapMutations(["updateFilters"]),
    preselectActivities: function (checked) {
      if (checked === "preselected") {
        this.dropdownActivitiesValue = this.dropdownActivitiesOptions.filter(
          (activitie) => activitie.preselected
        );
      } else {
        this.dropdownActivitiesValue = [];
      }
    },
    filter_displays: function (filter) {
      // console.log(filter);
      if (filter.value && filter.value.length) {
        if (filter.value.length > 0) {
          return true;
        } else {
          return false;
        }
      }
      if (filter.key === "bbox") {
        return false;
      }
    },
    filter_html_transform: function (filter) {
      var string = "";
      var first_label = filter.value[0].name;
      if (first_label.length > 17) {
        var ary = first_label.slice(0, 17).split(" ");
        first_label = ary.slice(0, -1).join(" ") + "...";
      }
      if (filter.value.length > 1) {
        let n_plus = filter.value.length - 1;
        string = first_label + `+${n_plus}`;
      } else {
        string = first_label;
      }
      return `\
                <div class="filter-display-label">${filter.key}</div>\
                <div style="font-weight: 900" class="filter-display-value">${string}</div>`;
    },
    selectBranch: function (payload) {
      this.$store.dispatch("select_branch", payload.siret);
    },
  },
  mounted() {
    // console.log(
    //   "this.$store.state.companyActivities",
    //   this.$store.state.companyActivities
    // );
    // console.log(this.dropdownActivitiesValue);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar.sticky-top {
  background: #f6f6f4;
  margin-right: -0.9em !important;
  margin-left: -1em;
  height: 4em;
}

table {
  width: 50%;
  margin: auto;
}

.title {
  display: flex;
  text-align: left;
  color: #187db6;
}

div>.filter-display-label {
  display: flex;
  text-align: left;
  color: #187db6;
  font-weight: 300;
  font-size: medium;
}

div>.filter-display-value {
  display: flex;
  text-align: left;
  color: #187db6;
  font-weight: 900;
  font-size: medium;
}

.title-card {
  color: #737373;
  font-weight: bold;
  text-align: left;
}

.card-text {
  color: #000000;
  text-align: left;
}

.form-control-file {
  width: auto;
}

#accordion-ener {
  margin: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
</style>
