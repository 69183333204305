<template>
  <div class="about">
    <h1>This is a sandbox page</h1>
    <p>loading={{ $store.state.loading }}</p>
    <hr />
    <input type="text" v-model.number="sandbox.a" />
    <input type="text" v-model.number="sandbox.b" />
    <button @click="check_api_get">Test API GET</button>
    <button @click="check_api_post">Test API POST</button>
    <p>{{ sandbox.result }}</p>
    <hr />
    <button @click="refresh_token">Refresh token</button>
    <button @click="get_me">Get me</button>
    <p>{{ me }}</p>
  </div>
</template>

<script>
import api from "@/api.js";

export default {
  name: "Sandbox",
  props: {
    msg: String,
  },
  data: function () {
    return {
      sandbox: { a: 2, b: 2, result: 4 },
      me: {},
    };
  },
  methods: {
    check_api_get: async function () {
      // console.log("check api")
      let response = await this.$http.get(api.get_url("sandbox/add"), {
        params: { a: this.sandbox.a, b: this.sandbox.b },
      });
      // console.log(response.body)
      this.sandbox.result = response.body.result;
    },
    check_api_post: async function () {
      // console.log("check api post")
      let response = await this.$http.post(
        api.get_url("sandbox/add-post"),
        JSON.stringify({ a: this.sandbox.a, b: this.sandbox.b })
      );
      // console.log(response.body)
      this.sandbox.result = response.body.result;
    },
    refresh_token: async function () {
      this.$store.dispatch("refresh_token");
    },
    get_me: async function () {
      let me_response = await this.$http.get(api.get_url("account/me/"));
      this.me = me_response.body;
    },
  },
};
</script>
