<template>
  <b-button @click="download_map()" id="camera_button" variant="primary"
    ><b-icon
      icon="camera"
      aria-hidden="true"
      variant="light"
      size="1.5"
    ></b-icon
  ></b-button>
</template>

<script>
import html2canvas from "html2canvas";

export default {
  name: "DownloadMap",
  methods: {
    download_map: function () {
    
    // Create the image of ADEME logo
    const logoImg = new Image(300, 300);
    console.log(logoImg);
    logoImg.src = "/img/logo.b9bc8503.png";

    // Set callback executed when image is fully loaded
      logoImg.onload = function () {
        // We Use this package because original mapbox-canvas do not include all markers
        html2canvas(document.querySelector(".mapboxgl-map")).then(function (
          map_canvas
        ) {
            // New canvas that will merge image and map canvas
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = map_canvas.width;
            canvas.height = map_canvas.height;

            // Draw map_canvas to the new canvas
            ctx.drawImage(map_canvas, 0, 0)

            // Draw ADEME logo to map canvas
            ctx.globalAlpha = 0.8;
            ctx.drawImage(logoImg, 0, 0, logoImg.width, logoImg.height, 0, 0, logoImg.width, logoImg.height);

            // Create an artificial link and click on it to download the new canvas as dataUrl
            const link = document.createElement("a");
            link.href = canvas.toDataURL();
            link.download = "carte.png";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
      };
    },
  },
};
</script>

<style scoped>
#camera_button{
    margin-top: 0.2em
}
</style>