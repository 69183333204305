<template>
  <div class="box col-6 full-height">
    <p id="loading-message" class="text text-center" v-if="!app_mounted">
      Nous chargeons les données de plus de 30 000 établissements. Cela durera environ 15 secondes.
    </p>
    <b-overlay opacity="0.2" spinner-variant="primary" :show="!app_mounted" no-wrap>
    </b-overlay>
    <Filters v-if="app_mounted" />
    <BranchProfile v-if="this.$store.state.selectedBranchId" />
    <BranchesList v-if="app_mounted" />
  </div>
</template>

<script>
import Filters from "@/components/Filters.vue";
import BranchProfile from "@/components/BranchProfile.vue";
import BranchesList from "@/components/BranchesList.vue";
export default {
  components: {
    Filters,
    BranchProfile,
    BranchesList,
  },
  name: "LeftPanel",
  props: {
    msg: String,
  },
  data() {
    return {
    };
  },
  computed: {
    app_mounted() {
      // console.log("App Mounted", this.$store.state.app_mounted);
      return this.$store.state.app_mounted;
    },
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#loading-message{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  color: primary;
  font-weight: bold;
  z-index: 2;
}
</style>
