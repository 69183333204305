import conf from "@/settings.js"


const api = {
    "get_url": function (route_name, options) {
        let params_url = ""
        let full_url = ""
        if (options != undefined) {
            params_url = new URLSearchParams(options.params)
            params_url = `?${params_url}`
        }
        if (route_name.endsWith("/")) {
            full_url = `${conf.api.root_url}/${route_name}` + params_url
        } else {
            full_url = `${conf.api.root_url}/${route_name}/` + params_url
        }
        return full_url
    }
}

export default api
